import React, { useEffect, useRef, useState, useContext } from "react";
import usePermission from "../../../hooks/usePermissions";
import DataTable from "react-data-table-component";
import {
  colors,
  columns,
  data,
  formattedPrice,
  getNumber,
  onlyNumbers,
  removeUnderscore,
} from "../../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../Components/Common/Button";
import DynamicForm from "../../../Components/Common/DynamicForm";
import FormInput from "../../../Components/Common/FormInput";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { fileExtension, getBase64Image } from "../../../utils/imageUtils";
import Icons from "../../../Components/Common/Icons";
import { useNav } from "../../../hooks/useNav";
import { useMutation, useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import Spinner from "../../../Components/Spinner";
import { getImage } from "../../../utils/generalUtils";
import CustomDropdown from "../../../Components/Common/Dropdown";
import useUpdateEstate from "./hooks/useUpdateEstate";
import useDeleteEstate from "./hooks/useDeleteEstate";
import useAllStates from "../../../hooks/useAllStates";
import useGetCity from "../../../hooks/useGetCity";
import useUploadImages from "./hooks/useUploadImages";
import useDeleteImages from "./hooks/useDeleteImages";
import CenteredText from "../../../Components/CenteredText";
import Modal from "../../../Components/Common/Modal";
import InfoTable from "../../../Components/Common/InfoTable";
import FooterButtons from "../../../Components/Common/FooterButtons";
import { useForm } from "react-hook-form";
import { convertToTitleCase } from "../../../utils/constants";
import { HiDotsHorizontal, HiDotsVertical } from "react-icons/hi";
import Dropdown from "react-bootstrap/Dropdown";
import LargeLoader from "../../../Components/Common/LargeLoader";
import { model } from "./model";
import { yupResolver } from "@hookform/resolvers/yup";
import { yesno, noyes, imageTypes } from "../../../utils/mockData";
import { estateStatus } from "../../../utils/mockData";
import MultitextInput from "../../../Components/Common/MultitextInput";
import CellSelector from "../../../Components/Common/CellSelector";
import useDeleteHouse from "../House/hooks/useDeleteHouse";
import useAuth from "../../../hooks/useAuth";
import FormAddressInput from "../../../Components/Common/FormAddressInput";
import CheckInput from "../../../Components/Common/CheckInput";
import PlanTypeContext from "../../../Context/PlanTypeContext";
import PlanTypeCard from "../../../Components/Common/PlanTypeCard";
import useGetEstateHouses from "./hooks/useGetEstateHouses";

const Estate = () => {
  const { authUser } = useAuth();
  const organizationName = authUser?.user?.organization?.name;

  const defaultImage =
    "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60";

  const { accessCreateHouse, accessUpdateEstate, accessDeleteEstate } =
    usePermission();
  const { planType, interestType, setPlanType, setInterestType } =
    useContext(PlanTypeContext);

  const { getRequest } = useAxiosBase();
  const params = useParams();
  const estateId = params?.id;
  const [houseId, setHouseId] = useState();
  const justCreated = params?.justCreated;

  const { data: getEstateHouses } = useGetEstateHouses(estateId);
  const { updateEstate } = useUpdateEstate(estateId);
  const { uploadingEstateImages } = useUploadImages(estateId);
  const { deleteEstateImages } = useDeleteImages(estateId);
  const { deleteHouse } = useDeleteHouse(houseId);

  console.log("getEstateHouses", getEstateHouses);

  if (justCreated) {
    toast.success("Created Successfully!");
  }

  const {
    data: estate1,
    isLoading,
    refetch,
    isRefetching,
    error,
  } = useQuery({
    queryKey: ["estate", estateId],
    queryFn: () =>
      getRequest({ url: `/public/estate/${estateId}` }).then(
        (res) => res.data.value?.value
      ),
    staleTime: 50000,
  });

  const { deleteEstate } = useDeleteEstate(estateId);
  const [housePrice, setHousePrice] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteHouseModal, setShowDeleteHouseModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [editableImageName, setEditableImageName] = useState("");
  const [editableImageType, setEditableImageType] = useState(imageTypes[0].id);
  const [imagesToDisplay, setImagesToDisplay] = useState([]);
  const [imageIDsToDelete, setImageIDsToDelete] = useState([]);
  const [imageObject, setImageObject] = useState([]);
  const [specificationsObject, setSpecificationsObject] = useState();
  const [specifications, setSpecifications] = useState();
  const [completionStatus, setCompletionStatus] = useState();
  const [stateId, setStateId] = useState(estate1?.city?.stateId);
  const [cityId, setCityId] = useState(estate1?.city?.id);
  const [dynamicForm, setDynamicForm] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [features, setFeatures] = useState([]);
  const [houseToDelete, setHouseToDelete] = useState();
  const [address, setAddress] = useState(null);
  const navigate = useNavigate();

  const saveImageName = debounce(() => {
    if (!editableImageName.trim()) {
      toast.error("Please input a valid property name.");
      return;
    }

    if (!editableImageType.trim()) {
      toast.error("Please select a valid image type.");
      return;
    }

    if (selectedImage) {
      // Map through the imagesToDisplay array to update the name and type of the selected image
      const updatedImages = imagesToDisplay.map((img) =>
        img.name === selectedImage.name
          ? { ...img, name: editableImageName, type: editableImageType }
          : img
      );
      const updatedImageObject = imageObject.map((img) =>
        img.name === selectedImage.name
          ? { ...img, name: editableImageName, type: editableImageType }
          : img
      );

      setImagesToDisplay(updatedImages);
      setImageObject(updatedImageObject);

      setShowImageModal(false);
      setSelectedImage(null); // Reset selected image
      setEditableImageName(""); // Reset editable name
      setEditableImageType(imageTypes[0]?.id); // Reset editable type
    }
  }, 300);

  const handleChange = async (event) => {
    const files = Array.from(event.target.files);
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

    if (files.length !== 0 && files[0].length !== 0) {
      for (let file of files) {
        // Check if file is of allowed type
        if (!allowedTypes.includes(file.type)) {
          toast.error("Only .png, .jpeg, and .jpg files are allowed.");

          // Reset file input value
          event.target.value = null;
          return;
        }

        if (file.size > 2 * 1024 * 1024) {
          toast.error("Image size shouldn't be more than 2MB.");

          // Reset file input value
          event.target.value = null;
          return;
        }

        // Check if an image with the same name already exists
        if (imagesToDisplay.some((img) => img.name === file.name)) {
          toast.error(`An image with the name ${file.name} already exists.`);

          // Reset file input value
          event.target.value = null;
          return;
        }

        const displayImg = URL.createObjectURL(file);
        setShowImageModal(true);
        setSelectedImage({ document: displayImg, name: file.name });
        setEditableImageName(file.name);
        setImagesToDisplay((prevImages) => [
          { document: displayImg, name: file.name },
          ...prevImages,
        ]);
        URL.revokeObjectURL(file);
        const base64 = await getBase64Image(file);
        const newImage = {
          base64: base64,
          name: file.name,
          optionId: "ESTATE_IMAGE",
          description: "",
          extension: fileExtension(file),
        };
        setImageObject((prevImages) => [newImage, ...prevImages]);
      }

      // Reset file input value
      event.target.value = null;
      return;
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setEditableImageName(image.name);
    setEditableImageType(image.type);
    setShowImageModal(true);
  };

  const hiddenFileInputRef = useRef(null);
  const handleClick = () => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    } else {
      console.error("The file input is not yet available.");
    }
  };

  const handleUploadIconClick = () => {
    hiddenFileInputRef.current.click();
  };

  const handleAddDynamicForm = () => {
    if (dynamicForm.length && dynamicForm[dynamicForm.length - 1].name === "") {
      toast.error(`Please add name for Feature Name ${dynamicForm.length}!`);
      return;
    }
    if (dynamicForm.length && dynamicForm[dynamicForm.length - 1].icon === "") {
      toast.error(`Please add an Icon for Feature Icon ${dynamicForm.length}!`);
      return;
    }
    let newForm = { name: "", icon: "" };
    setDynamicForm([...dynamicForm, newForm]);
  };

  const handleRemoveFeature = (index) => {
    let data = [...dynamicForm];
    let newData = data.filter((x, idx) => idx !== index);
    setDynamicForm(newData);
  };

  const { data: states } = useAllStates({
    onSuccess: (data) => {
      const sortedStates = data?.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    },
  });
  const { useCity } = useGetCity();

  const handleChangeEvent = (name, value, index) => {
    let dynamicInputData = [...dynamicForm];
    dynamicInputData[index][name] = value;
    setDynamicForm(dynamicInputData);
  };

  const [showPlanTypeModal, setShowPlanTypeModal] = useState(false);
  const [
    showSelectInterestOrCompoundType,
    setShowSelectInterestOrCompoundType,
  ] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const openDeleteModal = (image) => {
    setImageToDelete(image);
    setShowDeleteModal(true);
  };

  const handleRemoveImages = () => {
    if (imageToDelete) {
      setImagesToDisplay((prevImages) =>
        prevImages.filter((img) => img !== imageToDelete)
      );

      setImageObject((prevImages) =>
        prevImages.filter((img) => img.name !== imageToDelete.name)
      );

      setImageIDsToDelete((prevIds) => [...prevIds, imageToDelete.id]);
    }
    setShowDeleteModal(false);
  };

  useNav({
    hasBackButton: true,
    rightBtnTxt: "Add House",
    rightBtnClick: () => navigate(`/properties/house/create-house/${estateId}`),
    leftButtonDelete: true,
    leftBtnDelete: () => setShowModal(true),
  });

  const {
    register,
    handleSubmit: handleUpdateEstate,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(model) });

  const onSubmit = (data) => {
    const selectedFeatures = features
      .filter((feature) => feature.checked)
      .reduce((acc, feature) => {
        acc[feature.name] = true;
        return acc;
      }, {});

    data.ownerType = "DEVELOPER";
    data.cityId = cityId;
    data.address = address;
    data.images = [];
    data.completionStatus = completionStatus ?? null;
    data.feature = selectedFeatures ?? null;
    data.landSize =
      typeof data.landSize === "string"
        ? parseFloat(data.landSize.replace(/,/g, ""))
        : data.landSize;
    data.floors =
      typeof data.floors === "string"
        ? parseFloat(data.floors.replace(/,/g, ""))
        : data.floors;

    if (completionStatus != "UNDER_CONSTRUCTION") {
      delete data.completionDate;
    }
    if (imageIDsToDelete?.length > 0) {
      handleDeleteImagesFunction();
    }

    // console.log("submitUpdate", data);
    updateEstate.mutate(data, {
      onSuccess: () => {
        handleImageUploadFunction();
      },
    });
  };

  const handleImageUploadFunction = () => {
    if (estateId) {
      imageObject.map((image) => {
        uploadingEstateImages.mutate(image);
      });
    }
  };

  const handleDeleteImagesFunction = () => {
    deleteEstateImages.mutate(imageIDsToDelete);
  };

  const handleCopyPropertyLink = async () => {
    const propertyLink = `${window.location.origin}/properties/estate/${estateId}`;
    navigator.clipboard
      .writeText(propertyLink)
      .then(() => {
        toast.success("Property link copied to clipboard");
      })
      .catch((error) => {
        toast.error("Failed to copy property link to clipboard");
      });
  };

  const handlePlan = (houseId, option) => {
    setHouseId(houseId);
    if (option === "create") {
      setShowPlanTypeModal(true);
    } else {
      navigate(`/properties/house/plans/${houseId}`);
    }
  };

  const handleSelectInterestType = (e) => {
    let id = e;

    if (id) {
      setInterestType(id);
    }
  };

  const handleSelectPlanType = (e) => {
    let id = e;

    if (id) {
      setPlanType(id);
    }
  };

  const handleDeleteHouse = (rowId) => {
    const selectedHouse = estate1.houses?.find((house) => house.id === rowId);
    setHouseToDelete(selectedHouse);
    setHouseId(rowId);
    setShowDeleteHouseModal(true);
  };

  const handleSpecificationClick = (specName) => {
    setFeatures((prevSpecs) =>
      prevSpecs?.map((spec) =>
        spec.name === specName ? { ...spec, checked: !spec.checked } : spec
      )
    );
  };

  const handleToggleAll = () => {
    const newCheckedStatus = !allChecked;
    const updatedfeatures = features.map((spec) => ({
      ...spec,
      checked: newCheckedStatus,
    }));
    setFeatures(updatedfeatures);
    setAllChecked(newCheckedStatus);
  };

  const houseColumns = (viewProperty, handlePlan, handleDeleteHouse) => [
    {
      name: "",
      grow: 0,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          <img
            data-tag="allowRowEvents"
            height="60px"
            width="70px"
            alt={row?.images?.length ? row?.images[0]?.name : "alt image"}
            src={row?.images?.length > 0 ? row?.images[0] : defaultImage}
            style={{ borderRadius: "18px", padding: "8px" }}
          />
        </CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          PROPERTY NAME
        </p>
      ),
      sortable: true,
      wrap: true,
      selector: (row) => (
        <CellSelector loading={isRefetching}>{row.name ?? "---"}</CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          PROPERTY DETAILS
        </p>
      ),
      sortable: true,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          <>
            <span className="fw-semibold">{convertToTitleCase(row.type)}</span>{" "}
            <span className="circle_bar"></span> {row.address} {row.city}
          </>
        </CellSelector>
      ),
      wrap: true,
      grow: 2,
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          PRICE
        </p>
      ),
      sortable: true,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          <>₦ {row.price?.toLocaleString()}</>
        </CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          PLAN
        </p>
      ),
      sortable: true,
      grow: 0.5,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          {row.houseMortgagePlans?.length}
        </CellSelector>
      ),
    },
    // {
    //   name: <p className="fw-bold p-0 m-0" style={{fontSize: "12px"}}>House Type</p>,
    //   sortable: true,
    //   selector: (row) => convertToTitleCase(row.type),
    // },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          UNITS
        </p>
      ),
      sortable: true,
      grow: 0.6,
      selector: (row) => (
        <CellSelector loading={isRefetching}>{row.units}</CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          {" "}
          ACTION{" "}
        </p>
      ),
      sortable: false,
      grow: 0.5,
      center: true,
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle id={`dropdown-${row.id}`}>
            <HiDotsVertical className="fw-bold text-dark" />
          </Dropdown.Toggle>

          {!isRefetching && (
            <Dropdown.Menu>
              {row.houseMortgagePlans?.length > 0 && (
                <Dropdown.Item onClick={() => handlePlan(row.id, "view")}>
                  View Plans
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={() => {
                  setHousePrice(row.price);
                  handlePlan(row.id, "create");
                }}
              >
                Create Plan
              </Dropdown.Item>
              <Dropdown.Item onClick={() => viewProperty(row.id)}>
                View House
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger"
                onClick={() => handleDeleteHouse(row.id)}
              >
                Delete House
              </Dropdown.Item>
            </Dropdown.Menu>
          )}
        </Dropdown>
      ),
      allowOverflow: true,
    },
  ];

  useEffect(() => {
    if (stateId) {
      useCity.mutate(stateId);
    }
  }, [stateId]);

  useEffect(() => {
    if (estate1) {
      reset(estate1);
      setImagesToDisplay(estate1.images);
      setCompletionStatus(estate1.completionStatus);

      if (estate1?.completionDate) {
        const datePart = estate1?.completionDate.split("T")[0];
        setValue("completionDate", datePart);
      }

      setAddress(estate1.address);
    }
  }, [estate1, reset]);

  useEffect(() => {
    setCityId(estate1?.city?.id);
    setStateId(estate1?.city?.stateId);

    const iconData = {};
    estate1?.features.forEach((feature) => {
      iconData[feature.icon] = {
        icon: feature.icon,
        id: feature.id,
        name: feature.name,
      };
    });

    setSpecifications(iconData);
    setSpecificationsObject(estate1?.features);

    setFeatures([
      {
        name: "hasAmpleParkingSpace",
        checked: estate1?.features?.[0]?.hasAmpleParkingSpace,
        label: "Ample Parking Space",
      },
      {
        name: "hasUniformSecurity",
        checked: estate1?.features?.[0]?.hasUniformSecurity,
        label: "Uniformed Security",
      },
      {
        name: "hasCCTVSurveillanceSystem",
        checked: estate1?.features?.[0]?.hasCCTVSurveillanceSystem,
        label: "CCTV Surveillance System",
      },
      {
        name: "hasInverter",
        checked: estate1?.features?.[0]?.hasInverter,
        label: "Inverter",
      },
      {
        name: "has24HoursElectricity",
        checked: estate1?.features?.[0]?.has24HoursElectricity,
        label: "24/7 Electricity",
      },
      {
        name: "hasCinema",
        checked: estate1?.features?.[0]?.hasCinema,
        label: "Cinema",
      },
      {
        name: "hasBank",
        checked: estate1?.features?.[0]?.hasBank,
        label: "Bank",
      },
      {
        name: "hasInternetServices",
        checked: estate1?.features?.[0]?.hasInternetServices,
        label: "Internet Services",
      },
      {
        name: "hasFiberOptics",
        checked: estate1?.features?.[0]?.hasFiberOptics,
        label: "Fiber Optics",
      },
      {
        name: "hasReliableWaterSupply",
        checked: estate1?.features?.[0]?.hasReliableWaterSupply,
        label: "Reliable Water Supply",
      },
      {
        name: "hasChildernPlayground",
        checked: estate1?.features?.[0]?.hasChildernPlayground,
        label: "Children Playground",
      },
      {
        name: "hasEquestrainOrPoloCenter",
        checked: estate1?.features?.[0]?.hasEquestrainOrPoloCenter,
        label: "Equestrian/Polo Centers",
      },
      {
        name: "hasTennisCourt",
        checked: estate1?.features?.[0]?.hasTennisCourt,
        label: "Tennis Court",
      },
      {
        name: "hasGolfCourt",
        checked: estate1?.features?.[0]?.hasGolfCourt,
        label: "Golf Court",
      },
      {
        name: "hasLoungeOrBar",
        checked: estate1?.features?.[0]?.hasLoungeOrBar,
        label: "Lounge/Bar",
      },
      {
        name: "hasResturant",
        checked: estate1?.features?.[0]?.hasResturant,
        label: "Resturant",
      },
      {
        name: "hasLakesOrPonds",
        checked: estate1?.features?.[0]?.hasLakesOrPonds,
        label: "Lakes/Ponds",
      },
      {
        name: "hasGazebos",
        checked: estate1?.features?.[0]?.hasGazebos,
        label: "Gazebos",
      },
      {
        name: "hasChildcareFacilities",
        checked: estate1?.features?.[0]?.hasChildcareFacilities,
        label: "Childcare Facilities",
      },
      {
        name: "hasSchool",
        checked: estate1?.features?.[0]?.hasSchool,
        label: "School",
      },
      {
        name: "hasHospital",
        checked: estate1?.features?.[0]?.hasHospital,
        label: "Hospital",
      },
      {
        name: "hasShoppingComplex",
        checked: estate1?.features?.[0]?.hasShoppingComplex,
        label: "Shopping Complex",
      },
      {
        name: "hasChurchOrMosque",
        checked: estate1?.features?.[0]?.hasChurchOrMosque,
        label: "Church/Mosque",
      },
      {
        name: "hasGreeneryAndOpenGardens",
        checked: estate1?.features?.[0]?.hasGreeneryAndOpenGardens,
        label: "Greenery & Open Gardens",
      },
      { name: "hasGym", checked: estate1?.features?.[0]?.hasGym, label: "Gym" },
      {
        name: "hasBasketballCourt",
        checked: estate1?.features?.[0]?.hasBasketballCourt,
        label: "Basketball Court",
      },
      {
        name: "hasFootballPitch",
        checked: estate1?.features?.[0]?.hasFootballPitch,
        label: "Football Pitch",
      },
      {
        name: "hasSwimmingPool",
        checked: estate1?.features?.[0]?.hasSwimmingPool,
        label: "Swimming Pool",
      },
      {
        name: "hasClubHouse",
        checked: estate1?.features?.[0]?.hasClubHouse,
        label: "Clubhouse",
      },
    ]);
  }, [estate1]);

  useEffect(() => {
    if (features) {
      const checkedAll = features?.every((feature) => feature.checked);

      setAllChecked(checkedAll);
    }
  }, [features]);

  useEffect(() => {
    setPlanType("");
    setInterestType("");
  }, []);

  if (isLoading || isRefetching)
    return (
      <div style={{ height: "70vh" }}>
        {" "}
        <Spinner loadingText={"Loading Estate Details..."} />{" "}
      </div>
    );
  return (
    <div className="ps-3">
      <form onSubmit={handleUpdateEstate(onSubmit)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="">{estate1?.name}</h3>

          {/* Dropdownn for QR code  */}
          <div className="d-flex justify-content-between mb-2">
            <Dropdown>
              <Dropdown.Toggle>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#F0F0F0",
                    borderRadius: "50%",
                    width: "3rem",
                    height: "3rem",
                    padding: "0",
                  }}
                >
                  <HiDotsHorizontal
                    className="fw-bold"
                    style={{ height: "2.8rem", color: "#707070" }}
                  />
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  {" "}
                  <Icons iconName={"generate_QR"} className={"me-2"} /> Generate
                  QR Code
                </Dropdown.Item>
                <Dropdown.Item onClick={handleCopyPropertyLink}>
                  <Icons iconName={"copy_QR"} className={"me-2"} /> Copy Estate
                  Link
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    navigate(
                      `/properties/estate/${estateId}/construction-update`
                    )
                  }
                >
                  <Icons
                    iconName={"inspection"}
                    className="me-2 constuctionIcon"
                  />
                  View Construction Updates
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    navigate(
                      `/properties/estate/${estateId}/construction-update?action=create`
                    )
                  }
                >
                  <Icons
                    iconName={"property"}
                    className="me-2 constuctionIcon"
                  />
                  Create Construction Update
                </Dropdown.Item>
                {accessCreateHouse?.value && (
                  <Dropdown.Item
                    onClick={() =>
                      navigate(`/properties/house/create-house/${estateId}`)
                    }
                  >
                    {" "}
                    <Icons iconName={"plus"} className={"me-2"} /> Add Property
                  </Dropdown.Item>
                )}
                {accessDeleteEstate?.value && (
                  <Dropdown.Item
                    className="text-danger"
                    onClick={() => setShowModal(true)}
                  >
                    {" "}
                    <Icons iconName={"delete2"} className={"me-2"} /> Delete
                    Estate
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="">
          <div className="row">
            <div className="col">
              <div
                className="d-flex flex-column justify-content-center align-items-center bg-white"
                style={{
                  height: "248px",
                  borderRadius: "8px",
                  border: "1px solid #335f32",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                <span className="plus-icon d-flex justify-content-center align-items-center">
                  <Icons iconName={"plus"} />
                </span>
                <p className="fw-bold text-success uppercase mt-3">
                  ADD PROPERTY IMAGES
                </p>
              </div>
            </div>

            {/* Display First Three Images */}
            {imagesToDisplay.slice(0, 3)?.map((image) => (
              <div
                className="col house_image_wrapper position-relative"
                key={image.name}
                style={{
                  backgroundImage: `url(${image.document})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="img_overlay position-absolute"></div>
                <div onClick={() => handleImageClick(image)}>
                  <p className="img_open">
                    <span className="img_open-icon">
                      <Icons iconName={"open"} className="" />
                    </span>
                    Open
                  </p>
                </div>
                <div className="img_footer position-absolute">
                  <div className="mt-4 mb-3">
                    <div>
                      <p className="img_name m-0">{image.name}</p>
                    </div>
                    {image.type && (
                      <p className="img_type m-0 mt-2">
                        {convertToTitleCase(image.type)}
                      </p>
                    )}
                  </div>
                  <div className="img_delete_icon">
                    <Button
                      btnText={<Icons iconName={"delete"} />}
                      bordercolor={colors.black}
                      borderHover={colors.red}
                      backgroundcolor={colors.white}
                      backgroundHover={colors.dangerLight}
                      colorHover={colors.danger}
                      color={colors.danger}
                      iconcolorHover={colors.danger}
                      padding={"0"}
                      bordersize={2}
                      btntextcolor={colors.red}
                      width={"40px"}
                      height={"43px"}
                      type={"button"}
                      onBtnClick={() => openDeleteModal(image)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <input
            type="file"
            className="input-image"
            ref={hiddenFileInputRef}
            alt=""
            accept="image/*"
            onChange={handleChange}
            multiple
          />
        </div>

        {/* Thumbnails Section */}
        <div className="col h-25 thumbs-wrapper mt-3 mb-4">
          {imagesToDisplay.length > 3 &&
            imagesToDisplay.slice(3).map((image, index) => (
              <div className="thumb position-relative d-flex flex-column me-2">
                <div
                  className="position-relative w-full h-full thumb_img"
                  style={{
                    backgroundImage: `url(${image.document})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="thumb_img_overlay position-absolute"></div>
                  <p
                    className="thumb_open"
                    onClick={() => handleImageClick(image)}
                  >
                    <span className="thumb_open_icon">
                      <Icons iconName={"open"} className="" />
                    </span>
                    Open
                  </p>
                </div>
                <p className="delete_icon_thumbs">
                  <Button
                    btnText={<Icons iconName={"delete"} />}
                    bordercolor={colors.black}
                    borderHover={colors.red}
                    backgroundcolor={colors.white}
                    backgroundHover={colors.dangerLight}
                    colorHover={colors.danger}
                    color={colors.danger}
                    iconcolorHover={colors.danger}
                    padding={"0"}
                    bordersize={2}
                    btntextcolor={colors.red}
                    iconWidth={"15px"}
                    width={"25px"}
                    height={"29px"}
                    type={"button"}
                    onBtnClick={() => openDeleteModal(image)}
                  />
                </p>
                <p
                  className="my-0 text-success"
                  title={image.name}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                >
                  {image.name}
                </p>
              </div>
            ))}
        </div>

        <div className="row align-items-center my-4">
          <div className="mb-2 col-md-4 col-sm-12">
            <FormInput
              labelName="Estate Name"
              type="text"
              name="name"
              register={register("name")}
              error={errors?.name?.message}
            />
          </div>

          <div className="mb-2 col-md-4 col-sm-12">
            <CustomDropdown
              labelName={"State"}
              placeholder={convertToTitleCase(estate1?.city?.stateId)}
              options={states}
              onChange={(e) => setStateId(e.target.value)}
            />
          </div>

          <div className="mb-2 col-md-4 col-sm-12">
            <CustomDropdown
              labelName={"City"}
              placeholder={estate1?.city?.name}
              options={useCity?.data && useCity?.data}
              isLoading={useCity?.isLoading}
              disabled={useCity?.data?.length < 0}
              onChange={(e) => setCityId(e.target.value)}
            />
          </div>
          <div className="mb-2 col-md-4 col-sm-12">
            <FormInput
              labelName="Address"
              type="text"
              placeholder="Where exactly is this estate located?"
              name="address"
              register={register("address")}
              error={errors?.address?.message}
            />
          </div>
          {/* <div className="mb-2 col-md-4 col-sm-12">
            <FormAddressInput
              labelName="Address"
              value={address}
              placeholder="Where exactly is this estate located?"
              onChange={(value) => setAddress(value)}
            />
          </div> */}
          <div
            className="mb-2 col-md-4 col-sm-12"
            onBlur={(e) => {
              const youtubeLink = e.target.value.trim(); // Trim whitespace
              if (!youtubeLink) return; // If the field is empty, do nothing

              const pattern = /watch\?v=([a-zA-Z0-9_-]+)/;
              const match = youtubeLink.match(pattern);
              const embedPattern =
                /https:\/\/www.youtube.com\/embed\/([a-zA-Z0-9_-]+)/;
              const embedMatch = youtubeLink.match(embedPattern);

              if (embedMatch) {
                // Link is already in embed format, no need for further processing
                return;
              }

              if (match) {
                setValue(
                  "videoUrl",
                  `https://www.youtube.com/embed/${match[1]}`
                );
              } else {
                toast.warning(
                  "Invalid YouTube link. Please provide a valid link."
                );
                setValue("videoUrl", "");
              }
            }}
          >
            <FormInput
              labelName={"Video URL"}
              name={"videoUrl"}
              type={"text"}
              placeholder={"Add a YouTube video of the property"}
              register={register("videoUrl")}
            />
          </div>
          <div className="mb-2 col-md-4 col-sm-12">
            <FormInput
              labelName="Popular Landmark"
              type="text"
              placeholder="What popular structure is close to this estate?"
              register={register("landmark")}
              name="landmark"
            />
          </div>
          <div
            className="mb-2 col-md-4 col-sm-12"
            onBlur={(e) => {
              let land_Size = formattedPrice(e.target.value);
              if (!land_Size) {
                return;
              }
              setValue(
                "landSize",
                ((land_Size !== null || land_Size !== "") &&
                  land_Size.includes("Hectares")) ||
                  land_Size.includes("Hectare")
                  ? land_Size
                  : getNumber(land_Size) > 1
                  ? land_Size + " Hectares"
                  : land_Size + " Hectare"
              );
            }}
          >
            <FormInput
              labelName="Estate Land Size (In Hectares)"
              placeholder="Estate Land Size (e.g., 10.5 hectares)"
              register={register("landSize")}
              name="landSize"
            />
          </div>
          <div
            className="col-md-4 col-sm-12 p-2"
            onBlur={(e) =>
              setValue(
                "floors",
                !onlyNumbers(e.target.value)
                  ? formattedPrice("")
                  : formattedPrice(e.target.value)
              )
            }
          >
            <FormInput
              labelName="Number Floors"
              placeholder="Enter the number of floors in this Estate"
              register={register("floors")}
              name="floors"
            />
          </div>
          <div className="mb-2 col-md-4">
            <CustomDropdown
              labelName={"Completion Status"}
              placeholder={"Select"}
              value={convertToTitleCase(completionStatus)}
              options={estateStatus}
              onChange={(e) => setCompletionStatus(e.target.value)}
            />
          </div>
          {completionStatus === "UNDER_CONSTRUCTION" && (
            <div className="mb-2 col-md-4">
              <FormInput
                type={"date"}
                required
                labelName={"Completion Date"}
                placeholder={"When will it be completed?"}
                name="completionDate"
                register={register("completionDate")}
              />
            </div>
          )}
          <div className="mb-2 col-md-12">
            <MultitextInput
              label={"Estate Description"}
              placeholder={"Enter a detailed description of this estate..."}
              name="description"
              register={register("description")}
            />
          </div>
        </div>
        <div className="mt-4">
          <h2>Specifications and Amenities</h2>
          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 me-4"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"generalFeat"} />{" "}
              <span className="me-4 ps-2">General Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allChecked ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allChecked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={handleToggleAll}
              />
            </div>
          </div>
          <div className="row ps-2 mt-3">
            {features?.map((spec) => (
              <div
                key={spec.name}
                className="col-6 col-sm-4 col-md-3 col-lg-2 mb-4"
              >
                <p className="m-0 fw-bold">{spec.label}</p>
                <CheckInput
                  name={spec.name}
                  checked={spec.checked}
                  checkTick
                  width={"23px"}
                  height={"23px"}
                  bcolor={"#0000001A"}
                  bordercolor={"#CECECE"}
                  onChange={() => handleSpecificationClick(spec.name)}
                />
              </div>
            ))}
          </div>
        </div>

        <h3 className="p-0 m-0 mt-4">Houses in this Estate</h3>
        <div className="pe-3">
          {getEstateHouses?.length > 0 ? (
            <InfoTable
              columns={houseColumns(
                (houseId) => navigate(`/properties/house/${houseId}`),
                (rowId, option) => handlePlan(rowId, option),
                (rowId) => handleDeleteHouse(rowId)
              )}
              dataCollection={getEstateHouses}
              pointerOnHover={true}
              highlightOnHover={true}
              onRowClicked={(row, event) =>
                navigate(`/properties/house/${row.id}`)
              }
            />
          ) : (
            <div className="my-5">
              <CenteredText title="No Houses Added Yet">
                Houses added under this estate will populate here. You can also
                add an individual house by clicking the “add house” button below
                <div className="mt-2">
                  <Button
                    btnText={"Add House"}
                    width={"6rem"}
                    padding={"8px 0px"}
                    onBtnClick={() =>
                      navigate(`/properties/house/create-house/${estateId}`)
                    }
                  />
                </div>
              </CenteredText>
            </div>
          )}
        </div>
        {accessUpdateEstate?.value && (
          <FooterButtons
            loading={updateEstate.isLoading}
            disabled={updateEstate.isLoading}
            largeLoader={updateEstate.isLoading}
            cancelBtn={() => navigate(`/properties`)}
          />
        )}
      </form>

      {/* SELECT PLAN TYPE  */}
      <Modal
        show={showPlanTypeModal}
        closeModal={() => {
          setPlanType("");
          setInterestType("");
          setShowPlanTypeModal(false);
        }}
        title={
          showSelectInterestOrCompoundType
            ? "Select Interest Type"
            : "Select Plan Type"
        }
        subTitle={
          showSelectInterestOrCompoundType
            ? "What type of interest rate does this mortgage plan use?"
            : "What kind of plan do you want to create? Select One"
        }
        confirmModal={() => {
          if (
            removeUnderscore(planType) === "MORTGAGEPLAN" &&
            !showSelectInterestOrCompoundType
          ) {
            return setShowSelectInterestOrCompoundType(true);
          }
          navigate(`/properties/house/plans/create/${houseId}`);
          setShowPlanTypeModal(false);
        }}
        closeModalIcon={true}
        backBtn={showSelectInterestOrCompoundType}
        onBtnClick={() => {
          setPlanType(null);
          handleSelectInterestType(null);
          setShowSelectInterestOrCompoundType(false);
        }}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Continue"}
        width={"100%"}
        height={"100%"}
        crossLine={false}
        titleColor={colors.darkGreen}
        titleFont={"Millik"}
        titleFontSize={"30px"}
      >
        <>
          {showSelectInterestOrCompoundType ? (
            <div className="select_interest_type d-md-flex mt-5">
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Simple Interest or Markup Rate"}
                  iconName={"simpleInterest"}
                  description={
                    "This is a simple markup on the property price. For example, if a house is ₦50 Million with a 10% simple interest or markup rate, they’ll repay ₦50 Million + ₦5 Million (the 10% extra markup rate)."
                  }
                  onChange={() => handleSelectInterestType("SIMPLE_INTEREST")}
                  onClick={() => handleSelectInterestType("SIMPLE_INTEREST")}
                  selected={interestType === "SIMPLE_INTEREST"}
                />
              </div>

              <p className="text-center">OR</p>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Compound Interest"}
                  iconName={"compoundInterest"}
                  description={
                    "This is a compounding interest rate which is used in most traditional mortgages. Compounding rates accrue interest on a monthly basis. For example, if a house is ₦50 Million with a 10% compund interest, it means the 10% interest rate will compound monthly. We calculate the compund rate automatically and attach it to the customer’s repayment schedule."
                  }
                  onChange={() => handleSelectInterestType("COMPOUND_INTEREST")}
                  onClick={() => handleSelectInterestType("COMPOUND_INTEREST")}
                  selected={interestType === "COMPOUND_INTEREST"}
                />
              </div>
            </div>
          ) : (
            <div className="row m-0 mt-4">
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Outright Plan"}
                  iconName={"outrightPlan"}
                  description={
                    "With outright plans, customers pay 100% of the purchase price of the property in one bulk payment. There is no repayment necessary."
                  }
                  onChange={() => handleSelectPlanType("OUTRIGHT_PLAN")}
                  onClick={() => handleSelectPlanType("OUTRIGHT_PLAN")}
                  selected={planType === "OUTRIGHT_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Payment Plan"}
                  iconName={"paymentPlan"}
                  description={
                    " Payment plans allow customers pay a downpayment then spread the  remaining payment over a fixed time period."
                  }
                  onChange={() => handleSelectPlanType("PAYMENT_PLAN")}
                  onClick={() => handleSelectPlanType("PAYMENT_PLAN")}
                  selected={planType === "PAYMENT_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={" FMBN Rent To Own"}
                  iconName={"FMBN"}
                  description={
                    " This is a plan offered by the Federal Mortgage Bank of Nigeria.vIf you have partnered with FMBN to sell properties through their Rent To Own Plan, you may use this plan to boost your sales and collect applications and manage sales and documentation. However, you will be responsible for processing the customer’s application to FMBN."
                  }
                  onChange={() => handleSelectPlanType("RENT_TO_OWN")}
                  onClick={() => handleSelectPlanType("RENT_TO_OWN")}
                  selected={planType === "RENT_TO_OWN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={" Mortgage Plan"}
                  iconName={"mortgagePlan"}
                  description={
                    " This is a plan that attracts interest payments from customers and is paid over several years. Interest on mortgages can be simple or compond interest. We accomodate for both interest types."
                  }
                  cursor={"pointer"}
                  onChange={() => handleSelectPlanType("MORTGAGE_PLAN")}
                  onClick={() => handleSelectPlanType("MORTGAGE_PLAN")}
                  selected={planType === "MORTGAGE_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"  National Housing Fund"}
                  iconName={"nationalHousingFund"}
                  description={
                    "This is a plan from the Federal Mortgage Bank of Nigeria. If you are authorized by FMBN to sell NHF mortgages, Rent To Own you can do so through here. However, you will be responsible for processing the customer’s application to FMBN."
                  }
                  onChange={() => handleSelectPlanType("NHF_PLAN")}
                  onClick={() => handleSelectPlanType("NHF_PLAN")}
                  selected={planType === "NHF_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Family Homes Fund (FMF) Help To Own."}
                  iconName={"FMBN"}
                  description={
                    "This is a mortgage plan that offers loans of up to ₦35 Million Naira for customers to buy a house at 14% interest for up to 20 years."
                  }
                  onChange={() => handleSelectPlanType("HELP_TO_OWN")}
                  onClick={() => handleSelectPlanType("HELP_TO_OWN")}
                  selected={planType === "HELP_TO_OWN"}
                />
              </div>
            </div>
          )}
        </>
      </Modal>

      {/* DELETE IMAGE MODAL  */}
      <Modal
        show={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        title={"Delete Image"}
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Delete"
        confirmModal={() => handleRemoveImages()}
        cancelModal={() => setShowDeleteModal(false)}
      >
        <p className="text-center">
          Are you sure you want to delete this image?
        </p>
      </Modal>

      <Modal
        show={showImageModal}
        closeModal={() => setShowImageModal(false)}
        title="Name Uploaded Image"
        confirmButtonLabel="Save"
        crossLine={false}
        confirmModal={saveImageName}
        cancelModal={() => setShowImageModal(false)}
      >
        <div className="text-center mb-3">
          <p>
            This is how <strong>{editableImageName}</strong> will look to
            customers
          </p>
        </div>
        <div className="d-flex justify-content-center mb-3">
          {selectedImage && (
            <div className="position-relative">
              <div
                style={{
                  maxHeight: "20rem",
                  maxWidth: "100%",
                  overflow: "hidden",
                }}
              >
                <img
                  src={selectedImage.document}
                  alt={selectedImage.name}
                  className="img-fluid w-100 h-100"
                />
              </div>

              <div
                className="position-absolute d-flex justify-content-between align-items-center p-1"
                style={{ width: "100%", bottom: "1rem", left: "0.5rem" }}
              >
                <span
                  className="bg-white text-success px-2 py-1 fw-bold"
                  style={{ marginLeft: "10px", borderRadius: "15px" }}
                >
                  {editableImageName}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="mx-3 mt-4 mb-0">
          <CustomDropdown
            labelName={"Image Type"}
            width={"100%"}
            required
            placeholder={"Select"}
            options={imageTypes}
            value={convertToTitleCase(editableImageType)}
            onChange={(e) => setEditableImageType(e.target.value)}
          />
        </div>
        <div className="mx-3 mt-2 mb-0">
          <FormInput
            required
            labelName={"Image Name (name the part of this house)"}
            value={editableImageName}
            onChange={(name, value) => setEditableImageName(value)}
          />
        </div>
      </Modal>

      {/* DELETE MODAL  */}
      <Modal
        show={showModal}
        title={"Delete Estate"}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Delete"}
        isBtnLoading={deleteEstate.isLoading}
        disabled={deleteEstate.isLoading}
        closeModal={() => setShowModal(false)}
        confirmModal={() =>
          deleteEstate.mutate(null, {
            onSuccess: () => {
              setShowModal(false);
              setTimeout(
                () => navigate("/properties", { state: { refresh: true } }),
                400
              );
              return;
            },
          })
        }
      >
        {/* <h5 className="text-center">You are about to delete an estate</h5> */}
        <div className="text-center">
          Are you sure you want to delete {estate1?.name}?{" "}
          <strong className="text-danger">This action can't be undone</strong>
        </div>
      </Modal>

      {/* DELETE HOUSE MODAL */}
      <Modal
        show={showDeleteHouseModal}
        title={"You are about to delete a house"}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        isBtnLoading={deleteHouse.isLoading}
        closeModal={() => setShowDeleteHouseModal(false)}
        confirmModal={() =>
          deleteHouse.mutate(null, {
            onSuccess: () => {
              setShowDeleteHouseModal(false);
              refetch();
            },
          })
        }
      >
        <div className="text-center">
          Are you sure you want to delete {houseToDelete?.name ?? ""},{" "}
          {convertToTitleCase(houseToDelete?.type)} - {houseToDelete?.address}?{" "}
          <strong className="text-danger">This action can't be undone</strong>
        </div>
      </Modal>
    </div>
  );
};

export default Estate;
