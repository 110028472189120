import React, { useState, useEffect, useContext } from "react";
import usePermission from "../../../../hooks/usePermissions";
import { useNav } from "../../../../hooks/useNav";
import "./mortgagePlans.css";
import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import Button from "../../../../Components/Common/Button";
import Modal from "../../../../Components/Common/Modal";
import { useNavigate, useParams } from "react-router-dom";
import useGetPlans from "./hooks/useGetPlans";
import Spinner from "../../../../Components/Spinner";
// import { plansColumns } from "./constants";
import InfoTable from "../../../../Components/Common/InfoTable";
import InfoCard from "../../../../Components/Common/InfoCard";
import CenteredText from "../../../../Components/CenteredText";
import useDeletePlans from "./hooks/useDeletePlans";
import {
  convertToTitleCase,
  formattedPrice,
  removeUnderscore,
} from "../../../../utils/constants";
import { Dropdown } from "react-bootstrap";
import { HiDotsVertical } from "react-icons/hi";
import { colors } from "../../../../utils/constants";
import useGetHouse from "../hooks/useGetHouse";
import CellSelector from "../../../../Components/Common/CellSelector";
import TableLoader from "../../../../Components/Common/TableLoader";
import SkeletonLoader from "../../../../Components/SkeletonLoader";
import PlanTypeContext from "../../../../Context/PlanTypeContext";
import PlanTypeCard from "../../../../Components/Common/PlanTypeCard";
import { toast } from "react-toastify";

const MortgagePlans = () => {
  const navigate = useNavigate();
  const { getRequest } = useAxiosBase();

  const { planType, interestType, setPlanType, setInterestType } =
    useContext(PlanTypeContext);

  const params = useParams();
  const houseId = params?.houseId;

  const { data: house, isLoading: houseLoading } = useGetHouse(houseId);

  const [houseName, setHouseName] = useState();
  const [housePrice, setHousePrice] = useState();
  const [houseAddress, setHouseAddress] = useState();

  useNav({
    mainTitle: `Plans ${houseName ? `- ${houseName}` : ""}`,
    subTitle: (
      <p className="m-0">
        These are the different plans available for your customers to purchase{" "}
        {houseName} {houseAddress && <span className="circle_bar"></span>}{" "}
        {houseAddress}{" "}
        {housePrice && (
          <>
            <span className="circle_bar"></span>{" "}
            <strong> ₦{formattedPrice(housePrice)}</strong>
          </>
        )}
      </p>
    ),
    leftBtnClick: () => navigate("/properties"),
    // hasBackButton: true
  });

  const {
    accessViewPlans,
    accessCreatePlan,
    accessUpdatePlan,
    accessDeletePlan,
    setAccessDeletePlan,
    setAccessUpdatePlan,
  } = usePermission();

  const [planId, setPlanId] = useState("");

  const [pagination, setPagination] = useState(1);
  const {
    data: plans,
    isLoading,
    refetch,
    isRefetching,
  } = useGetPlans(houseId, pagination);

  const { deletePlans } = useDeletePlans(planId);

  const [
    showSelectInterestOrCompoundType,
    setShowSelectInterestOrCompoundType,
  ] = useState(false);
  const [showPlanTypeModal, setShowPlanTypeModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleSelectInterestType = (e) => {
    let id = e;

    if (id) {
      setInterestType(id);
    }
  };

  const handleSelectPlanType = (e) => {
    let id = e;

    if (id) {
      setPlanType(id);
    }
  };

  const showEditTeamMember = (rowId) => {
    navigate(`/properties/house/plans/${houseId}/${rowId}`);
  };

  // PLANS COLUMN
  const plansColumns = (showEditModal, showDeleteModal, loading) => {
    const columns = [
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            TYPE
          </p>
        ),
        sortable: true,
        wrap: true,
        grow: 1.5,
        selector: (row) => (
          <CellSelector loading={loading}>
            {convertToTitleCase(row?.type)}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            NAME
          </p>
        ),
        sortable: true,
        wrap: true,
        grow: 1.5,
        selector: (row) => (
          <CellSelector loading={loading}>{row?.name}</CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            DELIVERY LEVEL
          </p>
        ),
        sortable: true,
        wrap: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {convertToTitleCase(row?.deliveryLevel)}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            DELIVERY PRICE
          </p>
        ),
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            <>₦ {formattedPrice(row?.deliveryLevelPrice)}</>
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            MAX LOAN AMOUNT
          </p>
        ),
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {row?.maxLoanAmount === 0 ? (
              "---"
            ) : (
              <>₦ {formattedPrice(row?.maxLoanAmount)}</>
            )}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            DOWNPAYMENT
          </p>
        ),
        sortable: true,
        wrap: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {row?.deliveryLevelPrice > row?.maxLoanAmount ? (
              <>
                {row?.equityPercentage}% (₦{" "}
                {formattedPrice(
                  (
                    row?.deliveryLevelPrice *
                    (row.equityPercentage / 100)
                  )?.toFixed(2)
                )}
                )`
              </>
            ) : (
              <>
                {row?.equityPercentage}% (₦{" "}
                {formattedPrice(
                  (row?.maxLoanAmount * (row.equityPercentage / 100))?.toFixed(
                    2
                  )
                )}
                )`
              </>
            )}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            PERIOD
          </p>
        ),
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {row?.loanTenor
              ? `${row?.loanTenor} ${row?.loanTenor > 1 ? "Months" : "Month"}`
              : "---"}
          </CellSelector>
        ),
      },
      {
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            INTEREST RATE
          </p>
        ),
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            <>{row?.interestRate}%</>
          </CellSelector>
        ),
      },
    ];

    // Check if accessSendProspectReminder.value is true before adding the action column
    if (accessUpdatePlan?.value || accessDeletePlan?.value) {
      columns.push({
        name: (
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            {" "}
            ACTION{" "}
          </p>
        ),
        sortable: false,
        grow: 0.5,
        center: true,
        cell: (row) => (
          <Dropdown>
            <Dropdown.Toggle id={`dropdown-${row.id}`}>
              <HiDotsVertical className="fw-bold text-dark" />
            </Dropdown.Toggle>

            {!loading && (
              <Dropdown.Menu>
                {accessUpdatePlan?.value && (
                  <Dropdown.Item onClick={() => showEditModal(row.id)}>
                    Edit
                  </Dropdown.Item>
                )}

                {accessDeletePlan?.value && (
                  <Dropdown.Item
                    className="text-danger"
                    onClick={() => showDeleteModal(row.id)}
                  >
                    Delete
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            )}
          </Dropdown>
        ),
      });
    }
    return columns;
  };

  useEffect(() => {
    setHouseName(house?.name);
    setHousePrice(house?.price);
    setHouseAddress(house?.address);
  }, [house]);

  useEffect(() => {
    setPlanType("");
    setInterestType("");
  }, []);

  useEffect(() => {
    if (pagination) {
      refetch();
    }
  }, [pagination]);

  return (
    <div className="px-2 mortgage-plans">
      {accessViewPlans?.value ? (
        <>
          {accessCreatePlan?.value && (
            <div className="pe-1 text-end">
              <Button
                btnText={"Create Plan"}
                onBtnClick={() => setShowPlanTypeModal(true)}
              />
            </div>
          )}

          <div className="">
            <InfoCard
              mainValue={
                isLoading || isRefetching ? (
                  <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
                ) : (
                  plans?.totalRecords ?? "0"
                )
              }
              description={
                isLoading || isRefetching ? <SkeletonLoader /> : "Total Plans"
              }
            />
          </div>

          <div className="mb-4">
            {isLoading ? (
              <div className="mt-4">
                <TableLoader />
              </div>
            ) : plans?.data?.length > 0 ? (
              <div className="mt-2 me-2">
                <InfoTable
                  columns={plansColumns(
                    (rowId) => showEditTeamMember(rowId),
                    (rowId) => {
                      setPlanId(rowId);
                      setShowDeleteModal(true);
                    },
                    isRefetching
                  )}
                  dataCollection={plans?.data}
                  pointerOnHover={true}
                  highlightOnHover={true}
                  loadingTable={isRefetching}
                  paginationTotalRows={plans?.totalRecords}
                  onChangePage={(page) => setPagination(page)}
                  onRowClicked={(row, event) =>
                    navigate(`/properties/house/plans/${houseId}/${row?.id}`)
                  }
                />
              </div>
            ) : (
              <div className="mt-5">
                <CenteredText title={"You have no Plans at this time"}>
                  {" "}
                  To begin accepting applications and selling your properties
                  online to customers, click the create plan button above to
                  create a purchase plan.
                </CenteredText>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="mt-5">
          <CenteredText title={"Unauthorized Access"}>
            You don't have permission to view plans. Please contact the
            organization administrator for assistance.
          </CenteredText>
        </div>
      )}

      {/* SELECT PLAN TYPE  */}
      <Modal
        show={showPlanTypeModal}
        closeModal={() => {
          setPlanType("");
          setInterestType("");
          setShowPlanTypeModal(false);
        }}
        title={
          showSelectInterestOrCompoundType
            ? "Select Interest Type"
            : "Select Plan Type"
        }
        subTitle={
          showSelectInterestOrCompoundType
            ? "What type of interest rate does this mortgage plan use?"
            : "What kind of plan do you want to create? Select One"
        }
        confirmModal={() => {
          if (
            removeUnderscore(planType) === "MORTGAGEPLAN" &&
            !showSelectInterestOrCompoundType
          ) {
            return setShowSelectInterestOrCompoundType(true);
          }
          navigate(`/properties/house/plans/create/${houseId}`);
          setShowPlanTypeModal(false);
        }}
        closeModalIcon={true}
        backBtn={showSelectInterestOrCompoundType}
        onBtnClick={() => {
          setPlanType(null);
          handleSelectInterestType(null);
          setShowSelectInterestOrCompoundType(false);
        }}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Continue"}
        width={"100%"}
        height={"100%"}
        crossLine={false}
        titleColor={colors.darkGreen}
        titleFont={"Millik"}
        titleFontSize={"30px"}
      >
        <>
          {showSelectInterestOrCompoundType ? (
            <div className="select_interest_type d-md-flex mt-5">
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Simple Interest or Markup Rate"}
                  iconName={"simpleInterest"}
                  description={
                    "This is a simple markup on the property price. For example, if a house is ₦50 Million with a 10% simple interest or markup rate, they’ll repay ₦50 Million + ₦5 Million (the 10% extra markup rate)."
                  }
                  onChange={() => handleSelectInterestType("SIMPLE_INTEREST")}
                  onClick={() => handleSelectInterestType("SIMPLE_INTEREST")}
                  selected={interestType === "SIMPLE_INTEREST"}
                />
              </div>

              <p className="text-center">OR</p>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Compound Interest"}
                  iconName={"compoundInterest"}
                  description={
                    "This is a compounding interest rate which is used in most traditional mortgages. Compounding rates accrue interest on a monthly basis. For example, if a house is ₦50 Million with a 10% compund interest, it means the 10% interest rate will compound monthly. We calculate the compund rate automatically and attach it to the customer’s repayment schedule."
                  }
                  onChange={() => handleSelectInterestType("COMPOUND_INTEREST")}
                  onClick={() => handleSelectInterestType("COMPOUND_INTEREST")}
                  selected={interestType === "COMPOUND_INTEREST"}
                />
              </div>
            </div>
          ) : (
            <div className="row m-0 mt-4">
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Outright Plan"}
                  iconName={"outrightPlan"}
                  description={
                    "With outright plans, customers pay 100% of the purchase price of the property in one bulk payment. There is no repayment necessary."
                  }
                  onChange={() => handleSelectPlanType("OUTRIGHT_PLAN")}
                  onClick={() => handleSelectPlanType("OUTRIGHT_PLAN")}
                  selected={planType === "OUTRIGHT_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Payment Plan"}
                  iconName={"paymentPlan"}
                  description={
                    " Payment plans allow customers pay a downpayment then spread the  remaining payment over a fixed time period."
                  }
                  onChange={() => handleSelectPlanType("PAYMENT_PLAN")}
                  onClick={() => handleSelectPlanType("PAYMENT_PLAN")}
                  selected={planType === "PAYMENT_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={" FMBN Rent To Own"}
                  iconName={"FMBN"}
                  description={
                    "This is a plan offered by the Federal Mortgage Bank of Nigeria.vIf you have partnered with FMBN to sell properties through their Rent To Own Plan, you may use this plan to boost your sales and collect applications and manage sales and documentation. However, you will be responsible for processing the customer’s application to FMBN."
                  }
                  onChange={() => handleSelectPlanType("RENT_TO_OWN")}
                  onClick={() => handleSelectPlanType("RENT_TO_OWN")}
                  selected={planType === "RENT_TO_OWN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={" Mortgage Plan"}
                  iconName={"mortgagePlan"}
                  description={
                    " This is a plan that attracts interest payments from customers and is paid over several years. Interest on mortgages can be simple or compond interest. We accomodate for both interest types."
                  }
                  cursor={"pointer"}
                  onChange={() => handleSelectPlanType("MORTGAGE_PLAN")}
                  onClick={() => handleSelectPlanType("MORTGAGE_PLAN")}
                  selected={planType === "MORTGAGE_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"  National Housing Fund"}
                  iconName={"nationalHousingFund"}
                  description={
                    "This is a plan from the Federal Mortgage Bank of Nigeria. If you are authorized by FMBN to sell NHF mortgages, Rent To Own you can do so through here. However, you will be responsible for processing the customer’s application to FMBN."
                  }
                  onChange={() => handleSelectPlanType("NHF_PLAN")}
                  onClick={() => handleSelectPlanType("NHF_PLAN")}
                  selected={planType === "NHF_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Family Homes Fund (FMF) Help To Own."}
                  iconName={"FMBN"}
                  description={
                    "This is a mortgage plan that offers loans of up to ₦35 Million Naira for customers to buy a house at 14% interest for up to 20 years."
                  }
                  onChange={() => handleSelectPlanType("HELP_TO_OWN")}
                  onClick={() => handleSelectPlanType("HELP_TO_OWN")}
                  selected={planType === "HELP_TO_OWN"}
                />
              </div>
            </div>
          )}
        </>
      </Modal>

      {/* DELETE MODAL */}
      <Modal
        show={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        title={"Delete this Plan."}
        confirmModal={() =>
          deletePlans.mutate(null, {
            onSuccess: () => {
              refetch();
              setShowDeleteModal(false);
            },
          })
        }
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Delete"}
        isBtnLoading={deletePlans.isLoading}
        disabled={deletePlans.isLoading}
      >
        <p className="m-0 text-center">
          Are you sure you want to delete?{" "}
          <strong className="text-danger">This action can't be undone.</strong>
        </p>
      </Modal>
    </div>
  );
};

export default MortgagePlans;
