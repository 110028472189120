const houseType = [
  {
    id: "BUNGALOW",
    name: "Bungalow",
  },
  {
    id: "TERRACE",
    name: "Terrace",
  },
  {
    id: "FLAT",
    name: "Flat",
  },
  {
    id: "APARTMENT",
    name: "Apartment",
  },
  {
    id: "DETACHED_BUNGALOW",
    name: "Detached Bungalow",
  },
  {
    id: "SEMI_DETACHED_BUNGALOW",
    name: "Semi-detached Bungalow",
  },
  {
    id: "DUPLEX",
    name: "Duplex",
  },
  {
    id: "SUITE",
    name: "Suite",
  },
  {
    id: "DETACHED_DUPLEX",
    name: "Detached Duplex",
  },
  {
    id: "SEMI_DETACHED_DUPLEX",
    name: "Semi Detached Duplex",
  },
  {
    id: "TRIPLEX",
    name: "Triplex ",
  },
  {
    id: "DETACHED_TRIPLEX",
    name: "Detached Triplex ",
  },
  {
    id: "SEMI_DETACHED_TRIPLEX",
    name: "Semi Detached Triplex",
  },
  {
    id: "MASSIONETTE",
    name: "Massionette ",
  },
  {
    id: "QUADRUPLEX",
    name: "Quadruplex ",
  },
  {
    id: "TOWN_HOUSE",
    name: "Townhouse  ",
  },
  {
    id: "TWIN_VILLA",
    name: "Twin Villa  ",
  },
  {
    id: "VILLA",
    name: "Villa  ",
  },
];

const houseTypes = houseType
  .slice()
  .sort((a, b) => a.name.localeCompare(b.name));

export { houseTypes };

// IMAGE TYPE DROPDOWN STARTS
const imageType = [
  { id: "ACTUAL_IMAGE", name: "Actual Image " },
  { id: "PROTOTYPE_IMAGE ", name: "Prototype Image" },
];

const imageTypes = imageType
  .slice()
  .sort((a, b) => a.name.localeCompare(b.name));

export { imageTypes };

// IMAGE TYPE DROPDOWN ENDS

const planType = [
  { id: "OUTRIGHT_PLAN", name: "Outright " },
  {
    id: "PAYMENT_PLAN",
    name: "Payment Plan",
  },
  { id: "RENT_TO_OWN", name: "FMBN Rent To Own" },
  { id: "HELP_TO_OWN", name: "Family Homes Fund (FMF) Help To Own" },
  { id: "NHF_PLAN", name: "National Housing Fund (NHF)" },
  { id: "MORTGAGE_PLAN", name: "Mortgage Plan" },
];

const planTypes = planType.slice().sort((a, b) => a.name.localeCompare(b.name));

export { planTypes };
// PLAN TYPE DROPDOWN ENDS

// INTEREST TYPE DROPDOWN STARTS
const interestType = [
  { id: "SIMPLE_INTEREST", name: "Simple Interest" },
  { id: "COMPOUND_INTEREST", name: "Compound Interest" },
];

const interestTypes = interestType
  .slice()
  .sort((a, b) => a.name.localeCompare(b.name));

export { interestTypes };
// INTEREST TYPE DROPDOWN ENDS

// PROFILE JOB TITLES STARTS

const jobTitle = [
  { id: "ACCOUNTANT", name: "Accountant" },
  { id: "ADMINISTRATIVE_ASSISTANT", name: "Administrative Assistant" },
  {
    id: "AFTER_SALES_SUPPORT_SPECIALIST",
    name: "After-Sales Support Specialist",
  },
  { id: "ARCHITECT", name: "Architect" },
  { id: "BUSINESS_DEVELOPMENT_MANAGER", name: "Business Development Manager" },
  { id: "CALL_CENTER_AGENT", name: "Call Center Agent" },
  { id: "CEO", name: "CEO (Chief Executive Officer)" },
  { id: "CFO", name: "CFO (Chief Financial Officer)" },
  { id: "CTO", name: "Chief Technology Officer (CTO)" },
  { id: "COO", name: "COO (Chief Operating Officer)" },
  { id: "COMPLIANCE_OFFICER", name: "Compliance Officer" },
  { id: "CONTENT_MARKETING_SPECIALIST", name: "Content Marketing Specialist" },
  { id: "CONSTRUCTION_MANAGER", name: "Construction Manager" },
  { id: "CONSTRUCTION_WORKER", name: "Construction Worker" },
  { id: "CONTRACTS_MANAGER", name: "Contracts Manager" },
  {
    id: "CUSTOMER_RELATIONSHIP_MANAGER",
    name: "Customer Relationship Manager",
  },
  {
    id: "CUSTOMER_RELATIONSHIP_OFFICER",
    name: "Customer Relationship Officer",
  },
  {
    id: "CUSTOMER_SERVICE_REPRESENTATIVE",
    name: "Customer Service Representative",
  },
  { id: "DEPUTY_COMMERCIAL_DIRECTOR", name: "Deputy Commercial Director" },
  { id: "DIGITAL_MARKETING_SPECIALIST", name: "Digital Marketing Specialist" },
  { id: "FACILITY_MANAGER", name: "Facility Manager" },
  { id: "FINANCIAL_ANALYST", name: "Financial Analyst" },
  { id: "HEAD_OF_SALES", name: "Head of Sales" },
  { id: "HR_MANAGER", name: "HR Manager" },
  { id: "INTERIOR_DESIGNER", name: "Interior Designer" },
  { id: "INVENTORY_SPECIALIST", name: "Inventory Specialist" },
  { id: "IT_MANAGER", name: "IT Manager" },
  { id: "LOAN_OFFICER", name: "Loan Officer" },
  { id: "LOGISTICS_COORDINATOR", name: "Logistics Coordinator" },
  { id: "MARKETING_COORDINATOR", name: "Marketing Coordinator" },
  { id: "MARKETING_EXECUTIVE", name: "Marketing Executive" },
  { id: "MARKETING_EXECUTIVE, SALES_EXECUTIVE", name: "Marketing Executive, Sales Executive" },
  { id: "MARKETING_MANAGER", name: "Marketing Manager" },
  { id: "MARKET_RESEARCH_ANALYST", name: "Market Research Analyst" },
  { id: "MORTGAGE_BROKER", name: "Mortgage Broker" },
  { id: "NETWORK_ADMINISTRATOR", name: "Network Administrator" },
  { id: "OFFICE_MANAGER", name: "Office Manager" },
  { id: "OPERATIONS_MANAGER", name: "Operations Manager" },
  { id: "PARALEGAL", name: "Paralegal" },
  { id: "PROCUREMENT_MANAGER", name: "Procurement Manager" },
  { id: "PROJECT_COORDINATOR", name: "Project Coordinator" },
  { id: "PROJECT_MANAGER", name: "Project Manager" },
  { id: "PROPERTY_MANAGER", name: "Property Manager" },
  { id: "PUBLIC_RELATIONS_SPECIALIST", name: "Public Relations Specialist" },
  { id: "SAFETY_OFFICER", name: "Safety Officer" },
  { id: "SALES_REPRESENTATIVE", name: "Sales Representative" },
  { id: "SALES_MANAGER", name: "Sales Manager" },
  { id: "SALES_EXECUTIVE", name: "Sales Executive" },
  { id: "SOFTWARE_DEVELOPER", name: "Software Developer" },
  { id: "SUPPLY_CHAIN_MANAGER", name: "Supply Chain Manager" },
];

const jobTitles = jobTitle.slice().sort((a, b) => a.name.localeCompare(b.name));

export { jobTitles };
// PROFILE JOB TITLES END

// HOUSE INSPECTION TYPE DROPDOWN STARTS
export const houseInspectionTypes = [
  { id: "CUSTOMER_ASKED_ME_TO_CACNEL", name: "Customer asked me to cancel" },
  {
    id: "AGENT_UNAVAILABLE_TO_INSPECT_HOUSE",
    name: "Unavailable to inspect the house",
  },
  { id: "HOUSE_IS_SOLD_OUT", name: "The House has been recently sold out" },
  { id: "OTHER", name: "Other" },
];
// HOUSE INSPECTION TYPE DROPDOWN ENDS

// PLAN PROCESSING FEE TYPE DROPDOWN STARTS
const processingFee = [
  { id: "PERCENTAGE", name: "Percentage" },
  { id: "FIXED", name: "Fixed" },
];

const processingFees = processingFee
  .slice()
  .sort((a, b) => a.name.localeCompare(b.name));

export { processingFees };
// PLAN PROCESSING FEE TYPE DROPDOWN ENDS

export const titleList = [
  { id: "MR", name: "Mr." },
  { id: "MRS", name: "Mrs." },
  { id: "MISS", name: "Miss" },
  { id: "MS", name: "Ms." },
  { id: "DR", name: "Dr." },
  { id: "PROF", name: "Prof." },
  { id: "REV", name: "Rev." },
  { id: "LORD", name: "Lord" },
  { id: "LADY", name: "Lady" },
  { id: "SIR", name: "Sir" },
  { id: "DAME", name: "Dame" },
  { id: "CAPT", name: "Capt." },
  { id: "COL", name: "Col." },
  { id: "MAJ", name: "Maj." },
  { id: "LT", name: "Lt." },
  { id: "SGT", name: "Sgt." },
  { id: "CPL", name: "Cpl." },
  { id: "BRIG", name: "Brig." },
  { id: "GEN", name: "Gen." },
  { id: "ADM", name: "Adm." },
  { id: "COM", name: "Com." },
  { id: "CDR", name: "Cdr." },
  { id: "HON", name: "Hon." },
  { id: "JUDGE", name: "Judge" },
  { id: "MAYOR", name: "Mayor" },
];

export const yes = [{ id: "yes", name: "Yes" }];
export const no = [{ id: "no", name: "No" }];

export const yesno = [
  { id: "yes", name: "Yes" },
  { id: "no", name: "No" },
];

export const noyes = [
  { id: "no", name: "No" },
  { id: "yes", name: "Yes" },
];

export const houseStatusLists = [
  { id: "MOVE_IN_READY", name: "Move in Ready" },
  { id: "UNDER_CONSTRUCTION", name: "Under Construction" },
  // { id: "OFF_PLAN", name: "Off Plan" },
];

export const estateStatus = [
  { id: "COMPLETED", name: "Completed " },
  { id: "UNDER_CONSTRUCTION", name: "Under Construction" },
];

export const segmentLists = [
  { id: "RESIDENTIAL", name: "Residential Property" },
  { id: "COMMERCIAL", name: "Commercial Property" },
];

export const categoryLists = [
  { id: "AFFORDABLE_HOUSING", name: "Affordable Housing" },
  { id: "LUXURY_HOUSING", name: "Luxury Housing" },
  { id: "PREMIUM_HOUSING", name: "Premium Housing" },
];

export const salesPercentage = [
  { id: "5%", name: "5%" },
  { id: "10%", name: "10%" },
  { id: "15%", name: "15%" },
  { id: "20%", name: "20%" },
  { id: "25%", name: "25%" },
  { id: "30%", name: "30%" },
  { id: "35%", name: "35%" },
  { id: "40%", name: "40%" },
  { id: "45%", name: "45%" },
  { id: "50%", name: "50%" },
  { id: "55%", name: "55%" },
  { id: "60%", name: "60%" },
  { id: "65%", name: "65%" },
  { id: "70%", name: "70%" },
  { id: "75%", name: "75%" },
  { id: "80%", name: "80%" },
  { id: "85%", name: "85%" },
  { id: "90%", name: "90%" },
  { id: "95%", name: "95%" },
];

export const completionRateLists = [
  { name: "5 - 10%", id: "FIVE_TO_TEN" },
  { name: "11 - 15%", id: "ELEVEN_TO_FIFTEEN" },
  { name: "16 - 20%", id: "SIXTEEN_TO_TWENTY" },
  { name: "21 - 25%", id: "TWENTY_ONE_TO_TWENTY_FIVE" },
  { name: "26 - 30%", id: "TWENTY_SIX_TO_THIRTY" },
  { name: "31 - 35%", id: "THIRTY_ONE_TO_THIRTY_FIVE" },
  { name: "36 - 40%", id: "THIRTY_SIX_TO_FORTY" },
  { name: "41 - 45%", id: "FORTY_ONE_TO_FORTY_FIVE" },
  { name: "46 - 50%", id: "FORTY_SIX_TO_FIFTY" },
  { name: "51 - 55%", id: "FIFTY_ONE_TO_FIFTY_FIVE" },
  { name: "56 - 60%", id: "FIFTY_SIX_TO_SIXTY" },
  { name: "61 - 65%", id: "SIXTY_ONE_TO_SIXTY_FIVE" },
  { name: "66 - 70%", id: "SIXTY_SIX_TO_SEVENTY" },
  { name: "71 - 75%", id: "SEVENTY_ONE_TO_SEVENTY_FIVE" },
  { name: "76 - 80%", id: "SEVENTY_SIX_TO_EIGHTY" },
  { name: "81 - 85%", id: "EIGHTY_ONE_TO_EIGHTY_FIVE" },
  { name: "86 - 90%", id: "EIGHTY_SIX_TO_NINETY" },
  { name: "91 - 95%", id: "NINETY_ONE_TO_NINETY_FIVE" },
  { name: "96 - 100%", id: "NINETY_SIX_TO_ONE_HUNDRED" },
];

// LAND TITILOE DOC TYPE DROPDOWN STARTS
const landTitleDoc = [
  {
    id: "CERTIFICATE_OF_OCCUPANCY",
    name: "Certificate of Occupancy",
  },
  {
    id: "CERTIFICATE_OF_OCCUPANCY_AMAC",
    name: "Certificate of Occupancy (C of O) - AMAC",
  },
  {
    id: "CERTIFICATE_OF_OCCUPANCY_FCDA",
    name: "Certificate of Occupancy (C of O) - FCDA",
  },
  {
    id: "LETTER_OF_INTENT_WITH_APPROVAL_FOR_DEVELOPMENT",
    name: "Letter of Intent with Approval for Development ",
  },
  { id: "RIGHT_OF_OCCUPANCY", name: "Right of Occupancy (R of O)" },
  { id: "GOVERNOR_CONSTENT", name: "Governor’s Consent" },
  { id: "EXCISION_AND_GAZZETTE", name: "Excision and Gazette" },
  { id: "DEVELOPMENT_LEASE_AGREEMENT", name: "Development Lease Agreement" },
  { id: "FREE_HOLD_TITLE", name: "Free Hold Title" },
  {
    id: "GRANT_OF_PROBATE_AND_LETTER_OF_ADMINISTRATION",
    name: "Grant of Probate and Lett  er of Administration",
  },
];

const landTitleDocs = landTitleDoc
  .slice()
  .sort((a, b) => a.name.localeCompare(b.name));

export { landTitleDocs };

// LAND TITILOE DOC TYPE DROPDOWN ENDS

export const ownershipDoc = [
  { id: "DEED_OF_ASSIGNMENT", name: "Deed of Assignment" },
  { id: "DEED_OF_PARTNERSHIP", name: "Deed of Partnership" },
  { id: "DEED_OF_TRANSFER", name: "Deed of Transfer" },
];

export const weeks = [
  { id: "1", name: "Not more than 1 week" },
  { id: "2", name: "Not more than 2 weeks" },
  { id: "3", name: "Not more than 3 weeks" },
  { id: "4", name: "Not more than 4 weeks" },
  { id: "5", name: "Not more than 5 weeks" },
  { id: "6", name: "Not more than 6 weeks" },
  { id: "7", name: "Not more than 7 weeks" },
  { id: "8", name: "Not more than 8 weeks" },
  { id: "9", name: "Not more than 9 weeks" },
  { id: "10", name: "Not more than 10 weeks" },
];

export const endApplicationLists = [
  { id: "POOR_CREDIT_HISTORY", name: "Poor Credit History" },
  { id: "HOUSE_SOLD_OUT", name: "The House has been recently sold out" },
  { id: "PLAN_HAS_BEEN_REDACTED", name: "The Plan has been recently redacted" },
  { id: "HIGH_DEBT_TO_INCOME_RATIO", name: "High Debt-to-Income Ratio" },
  { id: "INSUFFICIENT_INCOME", name: "Insufficient Income" },
  { id: "POLICY_RESTRICTIONS", name: "Organization Policy Restrictions" },
  { id: "INCOMPLETE_DOCUMENTATION", name: "Incomplete Documentation" },
  { id: "UNSTABLE_EMPLOYMENT", name: "Unstable Employment" },
  { id: "OTHER", name: "Other" },
];

export const bankRejectApplicationLists = [
  { id: "POOR_CREDIT_HISTORY", name: "Poor Credit History" },
  { id: "HOUSE_SOLD_OUT", name: "The House has been recently sold out" },
  { id: "PLAN_HAS_BEEN_REDACTED", name: "The Plan has been recently redacted" },
  { id: "HIGH_DEBT_TO_INCOME_RATIO", name: "High Debt-to-Income Ratio" },
  { id: "INSUFFICIENT_INCOME", name: "Insufficient Income" },
  { id: "POLICY_RESTRICTIONS", name: "Bank Policy Restrictions" },
  { id: "INCOMPLETE_DOCUMENTATION", name: "Incomplete Documentation" },
  { id: "UNSTABLE_EMPLOYMENT", name: "Unstable Employment" },
  { id: "OTHER", name: "Other" },
];

// DELIVERY LEVEL LIST TYPE DROPDOWN STARTS
const deliveryLevelsList = [
  {
    id: "FOUNDATION_OR_DAMP_PROOF_CONCEPT",
    name: "Foundation / Damp Proof Concept (DPC)",
  },
  { id: "SHELL", name: "Shell" },
  { id: "CARCASS", name: "Carcass" },
  {
    id: "FULLY_COMPLETED_NOT_FURNISHED",
    name: "Fully Completed (Not Furnished)",
  },
  { id: "SEMI_FINISHED", name: "Semi Finished" },
  { id: "FULLY_COMPLETED_FURNISHED", name: "Fully Completed (Furnished)" },
];

const deliveryLevelsLists = deliveryLevelsList
  .slice()
  .sort((a, b) => a.name.localeCompare(b.name));

export { deliveryLevelsLists };

// DELIVERY LEVEL LIST TYPE DROPDOWN ENDS

// NOTIFICATION LIST TYPE DROPDOWN STARTS
const notificationType = [
  { id: "PROSPECT", name: "Prospect" },
  { id: "APPLICATION", name: "Application" },
  {
    id: "APPLICATION_REVIEW",
    name: "Application Review",
  },
  { id: "PARTNERSHIP_REQUEST", name: "Partnership request" },
];

const notificationTypes = notificationType
  .slice()
  .sort((a, b) => a.name.localeCompare(b.name));

export { notificationTypes };

// NOTIFICATION LIST TYPE DROPDOWN ENDS

// BANK LIST TYPE DROPDOWN STARTS
const bankType = [
  {
    id: "ACCESS_BANK_PLC",
    name: "Access Bank Plc",
  },
  {
    id: "ACCORD_SAVINGS_AND_LOANS",
    name: "Accord Savings & Loans",
  },
  {
    id: "ADAMAWA_SAVINGS_AND_LOANS",
    name: "Adamawa Savings & Loans",
  },
  {
    id: "AG_HOMES_SAVINGS_AND_LOANS",
    name: "AG Homes Savings & Loans",
  },
  {
    id: "ACHOL_SAVINGS_AND_LOANS",
    name: "AHCOL Savings & Loans",
  },
  {
    id: "ASO_SAVINGS_AND_LOANS_PLC",
    name: "Aso Savings & Loans Plc",
  },
  {
    id: "ABBEY_MORTGAGE_BANK_PLC",
    name: "Abbey Mortgage Bank Plc",
  },
  {
    id: "AKWA_SAVINGS_AND_LOANS",
    name: "Akwa Savings & Loans",
  },
  {
    id: "CENTAGE_SAVINGS_AND_LOANS",
    name: "Centage Savings & Loans",
  },
  {
    id: "CITY_CODE_SAVINGS_AND_LOANS",
    name: "City-Code Savings & Loans",
  },
  {
    id: "CITIBANK_NIGERIA_LIMITED",
    name: "Citibank Nigeria Limited",
  },
  {
    id: "COOP_SAVINGS_AND_LOANS",
    name: "Coop Savings and Loans",
  },
  {
    id: "DELTA_MORTGAGE_FINANCE",
    name: "Delta Mortgage Finance",
  },
  {
    id: "ECOBANK_NIGERIA_PLC",
    name: "Ecobank Nigeria Plc",
  },
  {
    id: "FBN_MORTGAGE_LTD",
    name: "FBN Mortgages Ltd",
  },
  {
    id: "FEDERAL_MORTGAGE_BANK_OF_NIGERIA",
    name: "Federal Mortgage Bank of Nigeria",
  },
  {
    id: "FHA_HOMES_LIMITED",
    name: "FHA Homes Limited",
  },
  {
    id: "FIDELITY_BANK_PLC",
    name: "Fidelity Bank Plc",
  },
  {
    id: "FIRST_BANK_NIGERIA_LIMITED",
    name: "First Bank Nigeria Limited",
  },
  {
    id: "FIRST_CITY_MONUMENT_BANK_PLC",
    name: "First City Monument Bank Plc",
  },
  {
    id: "FIRST_GENERATION_HOMES",
    name: "First Generation Homes",
  },
  {
    id: "GLOBAL_TRUST_SAVINGS_AND_LOANS",
    name: "Global Trust Savings & Loans",
  },
  {
    id: "GATEWAY_SAVINGS_AND_LOANS",
    name: "Gateway Savings & Loans",
  },
  {
    id: "GLOBUS_BANK_LIMITED",
    name: "Globus Bank Limited",
  },
  {
    id: "HAGGI_MORTGAGE_BANK",
    name: "Haggai Mortgage Bank",
  },
  {
    id: "HOME_BASE_MORTGAGE",
    name: "Home-Base Mortgage",
  },
  {
    id: "IMPERIAL_MORTGAGE_BANK",
    name: "Imperial Mortgage Bank",
  },
  {
    id: "INFINITY_TRUST_MORTGAGE_BANK_PLC",
    name: "Infinity Trust Mortgage Bank Plc",
  },
  {
    id: "JAIZ_BANK_PLC",
    name: "Jaiz Bank Plc",
  },
  {
    id: "JUBILEE_LIFE_MORTGAGE_BANK",
    name: "Jubilee-Life Mortgage Bank",
  },
  {
    id: "JIGAWA_SAVINGS_AND_LOANS",
    name: "Jigawa Savings & Loans",
  },
  {
    id: "KEBBI_STATE_HOMES_S_AND_L",
    name: "Kebbi State Homes S & L",
  },
  {
    id: "KEYSTONE_BANK_LIMITED",
    name: "Keystone Bank Limited",
  },
  {
    id: "KOGI_STATE_SAVINGS_AND_LOANS",
    name: "Kogi State Savings & Loans",
  },
  {
    id: "LAGOS_BUILDING_AND_INVEST_CO",
    name: "Lagos Building and Invest. Co.",
  },
  {
    id: "LOTUS_BANK_LTD",
    name: "Lotus Bank Ltd",
  },
  {
    id: "MAYFRESH_MORTGAGE_BANK",
    name: "Mayfresh Mortgage Bank",
  },
  {
    id: "MSGL_MORTGAGE_BANK",
    name: "MGSL Mortgage Bank",
  },
  {
    id: "MUTUAL_ALLIANCE_SAVINGS_AND_LOANS",
    name: "Mutual Alliance Savings & Loans",
  },
  {
    id: "NEW_PRUDENTIAL_BUILDING_SOCIETY",
    name: "New Prudential Building Society",
  },
  {
    id: "NIGERIA_POLICE_MORTGAGE_BANK",
    name: "Nigeria Police Mortgage Bank",
  },
  {
    id: "OMOLUABI_LIVING_SPRING_SAVINGS_LOANS",
    name: "Omoluabi (Living Spring) Savings & Loans",
  },
  {
    id: "PARALLEX_BANK_LTD",
    name: "Parallex Bank Ltd",
  },
  {
    id: "PLATINUM_MORTGAGE_BANK",
    name: "Platinum Mortgage Bank",
  },
  {
    id: "POLARIS_BANK_PLC",
    name: "Polaris Bank Plc",
  },
  {
    id: "PREMIUM_TRUST_BANK",
    name: "Premium Trust Bank",
  },
  {
    id: "PROVIDUS_BANK",
    name: "Providus Bank",
  },
  {
    id: "REFUGE_HOME_S_AND_L",
    name: "Refuge Home S & L",
  },
  {
    id: "RESORT_SAVINGS_AND_LOANS",
    name: "Resort Savings & Loans",
  },
  {
    id: "REFINE_TRUST_MORTGAGE_BANK_LTD",
    name: "Refine Trust Mortgage Bank Ltd",
  },
  {
    id: "SAFE_TRUST_SAVING_LOANS",
    name: "Safe Trust Savings & Loans",
  },
  {
    id: "SIGNATURE_BANK_LIMITED",
    name: "Signature Bank Limited",
  },
  {
    id: "SKYFIELD_SAVINGS_AND_LOANS",
    name: "Skyfield Savings and Loans",
  },
  {
    id: "STANBIC_IBTC_BANK_PLC",
    name: "Stanbic IBTC Bank Plc",
  },
  {
    id: "STANDARD_CHARTERED_BANK_NIGERIA_LTD",
    name: "Standard Chartered Bank Nigeria Ltd",
  },
  {
    id: "STB_BUILDING_SOCIETY",
    name: "STB Building Society",
  },
  {
    id: "SUNTRUST_BANK_NIGERIA_LIMITED",
    name: "SunTrust Bank Nigeria Limited",
  },
  {
    id: "SUNTRUST_MORTGAGE_BANK",
    name: "SunTrust Mortgage Bank",
  },
  {
    id: "TAJ_BANK",
    name: "Taj Bank",
  },
  {
    id: "TARABA_STATE_SAVINGS_AND_LOANS",
    name: "Taraba State Savings & Loans",
  },
  {
    id: "TITAN_TRUST_BANK_LTD",
    name: "Titan Trust Bank Ltd",
  },
  {
    id: "TRANS_ATLANTIC_MORTGAGES_LIMITED",
    name: "Trans-Atlantic Mortgages Limited",
  },
  {
    id: "TRUST_BOND_MORTGAGE_BANK",
    name: "Trust Bond Mortgage Bank",
  },
  {
    id: "UNITED_BANK_FOR_AFRICA_PLC",
    name: "United Bank For Africa Plc",
  },
  {
    id: "UNITED_OR_SPRING_MORTGAGE",
    name: "United/Spring Mortgage",
  },
  {
    id: "UNION_BANK_OF_NIGERIA_PLC",
    name: "Union Bank of Nigeria Plc",
  },
  {
    id: "UNITY_BANK_PLC",
    name: "Unity Bank Plc",
  },
  {
    id: "ZENITH_BANK_PLC",
    name: "Zenith Bank Plc",
  },
];

const bankTypes = bankType.slice().sort((a, b) => a.name.localeCompare(b.name));

export { bankTypes };

// BANK LIST TYPE DROPDOWN ENDS
