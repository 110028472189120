import React, { useContext, useEffect, useMemo, useState } from "react";
import usePermission from "../../hooks/usePermissions";
import "../Properties/properties.css";
import Button from "../../Components/Common/Button";
import Slider from "react-slick";
import {
  colors,
  columns,
  convertToTitleCase,
  removeUnderscore,
} from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../../Components/Common/Modal";
import { useNav } from "../../hooks/useNav";
import FormInput from "../../Components/Common/FormInput";
import Spinner from "../../Components/Spinner";
import EstateCard from "./EstateCards";
import CenteredText from "../../Components/CenteredText";
import { toast } from "react-toastify";
import useEstate from "../../hooks/useEstate";
import useHouse from "../../hooks/useHouse";
import EstatesModalContent from "./EstatesModalContent";
import { sliderSettings } from "./constant";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InfoTable from "../../Components/Common/InfoTable";
import { Carousel } from "primereact/carousel";
// import { getImage } from "../../utils/generalUtils";
import defaultHouse from "../../house.svg";
import { getImage } from "../../utils/generalUtils";
import { Dropdown } from "react-bootstrap";
import { HiDotsVertical } from "react-icons/hi";
import SkeletonLoader from "../../Components/SkeletonLoader";
import CellSelector from "../../Components/Common/CellSelector";
import useDeleteHouse from "./House/hooks/useDeleteHouse";
import TableLoader from "../../Components/Common/TableLoader";
import PlanTypeCard from "../../Components/Common/PlanTypeCard";
import PlanTypeContext from "../../Context/PlanTypeContext";

const Properties = () => {
  const {
    accessCreateHouse,
    accessHouseViewing,
    accessCreateEstate,
    accessEstateViewing,
  } = usePermission();

  const navigate = useNavigate();
  const location = useLocation();

  const { planType, interestType, setPlanType, setInterestType } =
    useContext(PlanTypeContext);

  const defaultImage =
    "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60";

  const [openModal, setOpenModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [openEstateModal, setOpenEstateModal] = useState(false);
  const [houseId, setHouseId] = useState();
  const [housePrice, setHousePrice] = useState(0);
  const [houseToDelete, setHouseToDelete] = useState();

  const [showModal, setShowModal] = useState(false);

  const [showPlanTypeModal, setShowPlanTypeModal] = useState(false);
  const [
    showSelectInterestOrCompoundType,
    setShowSelectInterestOrCompoundType,
  ] = useState(false);

  const { deleteHouse } = useDeleteHouse(houseId);

  const {
    data: estateCollection,
    error,
    isLoading,
    refetch: refetchEstate,
    isRefetching: isRefetchingEstate,
  } = useEstate();

  const [housePagination, setHousePagination] = useState(1);
  const [loadingHouseTable, setLoadingHouseTable] = useState(false);
  const {
    data: houseCollection,
    error: houseError,
    isLoading: houseLoading,
    refetch: refetchHouseCollection,
    isRefetching,
  } = useHouse(housePagination);

  const estates = useMemo(() => {
    if (!searchInput) return estateCollection;
    if (searchInput.length >= 1) {
      return estateCollection.filter((est) => {
        return est.name
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase());
      });
    }
  }, [searchInput, estateCollection]);

  if (error || houseError) {
    if (toast === null) toast.error(error?.message);
  }

  useNav({
    mainTitle: "My Properties",
    isProp: true,
    houses: houseCollection?.totalRecords ?? 0,
    estates: estates?.length,
  });

  const [selectedEstateId, setSelectedEstateId] = useState(null);
  const handleSelectEstate = (id) => {
    setSelectedEstateId(id);
  };

  const handleAction = (rowId, action) => {
    console.log("rowId", rowId);
    setHouseId(rowId);
    // const member = teamMembers?.data?.find((member) => member.id === rowId);
    // setRoleName(member?.applicationRole?.name);
    // setMemberName(member?.name);

    if (action === "viewPlans") {
      navigate(`/properties/house/plans/${rowId}`);
    } else if (action === "create") {
      setShowPlanTypeModal(true);
    } else if (action === "viewConstruction") {
      navigate(`/properties/house/${rowId}/construction-update`);
    } else if (action === "createConstruction") {
      navigate(`/properties/house/${rowId}/construction-update?action=create`);
    } else if (action === "viewHouse") {
      navigate(`/properties/house/${rowId}`);
    } else {
      const selectedHouse = houseCollection?.data?.find(
        (house) => house.id === rowId
      );
      setHouseToDelete(selectedHouse);
      setShowModal(true);
    }
  };

  const handleSelectInterestType = (e) => {
    let id = e;

    if (id) {
      setInterestType(id);
    }
  };

  const handleSelectPlanType = (e) => {
    let id = e;

    if (id) {
      setPlanType(id);
    }
  };

  //  HOUSE PROPERTY ESTATE
  const houseColumns = (viewProperty, handlePlan, handleDeleteHouse) => [
    {
      name: "",
      grow: 0,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          <img
            data-tag="allowRowEvents"
            height="60px"
            width="70px"
            alt={row?.images?.length ? row?.images[0]?.name : "alt image"}
            src={row?.images?.length > 0 ? row?.images[0] : defaultImage}
            style={{ borderRadius: "18px", padding: "8px" }}
          />
        </CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          PROPERTY NAME
        </p>
      ),
      sortable: true,
      wrap: true,
      selector: (row) => (
        <CellSelector loading={isRefetching}>{row.name ?? "---"}</CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          PROPERTY DETAILS
        </p>
      ),
      sortable: true,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          <>
            <span className="fw-semibold">{convertToTitleCase(row.type)}</span>{" "}
            <span className="circle_bar"></span> {row.address} {row.city}
          </>
        </CellSelector>
      ),
      wrap: true,
      grow: 2,
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          PRICE
        </p>
      ),
      sortable: true,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          <>₦ {row.price?.toLocaleString()}</>
        </CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          ESTATE
        </p>
      ),
      sortable: true,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          {row.estateName ?? "None Selected"}
        </CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          PLANS
        </p>
      ),
      sortable: true,
      grow: 0.5,
      selector: (row) => (
        <CellSelector loading={isRefetching}>
          {row.houseMortgagePlans?.length}
        </CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          UNITS
        </p>
      ),
      sortable: true,
      grow: 0.6,
      selector: (row) => (
        <CellSelector loading={isRefetching}>{row.units}</CellSelector>
      ),
    },
    {
      name: (
        <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
          {" "}
          ACTION{" "}
        </p>
      ),
      sortable: false,
      grow: 0.5,
      center: true,
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle id={`dropdown-${row.id}`}>
            <HiDotsVertical className="fw-bold text-dark" />
          </Dropdown.Toggle>

          {!isRefetching && (
            <Dropdown.Menu>
              {row.houseMortgagePlans?.length > 0 && (
                <Dropdown.Item
                  onClick={() => handleAction(row.id, "viewPlans")}
                >
                  View Plans
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={() => {
                  setHousePrice(row.price);
                  handleAction(row.id, "create");
                }}
              >
                Create Plan
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleAction(row.id, "viewConstruction")}
              >
                View Construction Updates
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleAction(row.id, "createConstruction")}
              >
                Create Construction Update
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleAction(row.id, "viewHouse")}>
                View House
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger"
                onClick={() => handleAction(row.id, "deleteHouse")}
              >
                Delete House
              </Dropdown.Item>
            </Dropdown.Menu>
          )}
        </Dropdown>
      ),
      allowOverflow: true,
    },
  ];

  useEffect(() => {
    refetchEstate();
    refetchHouseCollection();
  }, [location.state]);

  useEffect(() => {
    setLoadingHouseTable(isRefetching);
  }, [isRefetching]);

  useEffect(() => {
    if (housePagination) {
      refetchHouseCollection();
    }
  }, [housePagination]);

  useEffect(() => {
    setPlanType("");
    setInterestType("");
  }, []);

  const estateCardTemp = (estate) => {
    const image = getImage;
    return (
      <div
        className="card pr-card mx-2"
        onClick={() => navigate(`/properties/estate/${estate.id}`)}
      >
        {isRefetchingEstate ? (
          <div className="mx-2 mb-2">
            <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
          </div>
        ) : (
          <div className="card-body slider-body pr-cr-cd">
            <img
              src={image(estate.images) ?? defaultHouse}
              alt={estate.name}
              style={{ width: "100%", height: "95%" }}
            />
          </div>
        )}

        <div className="card-details text-center p-2 pb-3">
          {isRefetchingEstate ? (
            <div className="mb-2">
              <SkeletonLoader height={"1.5rem"} borderRadius={"9px"} />
            </div>
          ) : (
            <div className="row pr-title">
              <h6>{estate.name}</h6>
            </div>
          )}

          {isRefetchingEstate ? (
            <SkeletonLoader />
          ) : (
            <div className="row pr-desc">
              <p className="p-0 mb-2">{estate.address}</p>
            </div>
          )}

          {isRefetchingEstate ? (
            <SkeletonLoader />
          ) : (
            <div className="row pr-hou">
              <span>
                {estate.houses?.length}{" "}
                {estate.houses?.length > 1 ? "HOUSES" : "HOUSE"}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const EstateCardSkeleton = () => (
    <div className="card pr-card mx-2">
      <div className="mx-2 mb-2">
        <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
      </div>
      <div className="card-details text-center p-2 pb-3">
        <div className="mb-2">
          <SkeletonLoader height={"1.5rem"} borderRadius={"9px"} />
        </div>
        <SkeletonLoader />
        <SkeletonLoader />
      </div>
    </div>
  );

  return (
    <div className="ps-3 pe-4 my-5">
      {/* First section */}
      {accessEstateViewing?.value ? (
        <>
          <div className="d-flex justify-content-between">
            <h3 style={{ fontSize: "24px" }}>
              Estates - (<span>{estates?.length ?? 0}</span>)
            </h3>
            {accessCreateEstate?.value && (
              <div className="d-flex justify-content-end">
                <Button
                  btnText="Add Estate"
                  type={"button"}
                  onBtnClick={() =>
                    navigate("/properties/estate/create-estate")
                  }
                />
              </div>
            )}
          </div>
          <div>
            {isLoading ? (
              <div className="mb-5 mt-3">
                <Carousel
                  value={Array(4).fill(0)}
                  numVisible={4}
                  numScroll={1}
                  itemTemplate={() => <EstateCardSkeleton />}
                  style={{
                    backgroundColor: "#F0F0F0",
                    paddingTop: "33px",
                    paddingLeft: "10px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            ) : estates?.length ? (
              <div className="mb-5 mt-3">
                <Carousel
                  value={estates}
                  numVisible={4}
                  numScroll={1}
                  itemTemplate={estateCardTemp}
                  style={{
                    backgroundColor: "#F0F0F0",
                    paddingTop: "33px",
                    paddingLeft: "10px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            ) : (
              <div className="my-5">
                <CenteredText title="No Estates Added Yet">
                  An estate is a collection of houses in the same location.
                  Click the “add estate” button above to create an estate and
                  then add the types of houses that are available in the estate.
                </CenteredText>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="my-5">
          <CenteredText title={"Unauthorized Access"}>
            You don't have permission to view estates. Please contact the
            organization administrator for assistance.
          </CenteredText>
        </div>
      )}

      {/* Second Section */}
      {accessHouseViewing?.value ? (
        <>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <h3 style={{ fontSize: "24px" }}>
              Houses - (<span>{houseCollection?.data?.length ?? 0}</span>)
            </h3>
            {accessCreateHouse?.value && (
              <div className="d-flex justify-content-end">
                <Button
                  btnText="Add House"
                  type={"button"}
                  onBtnClick={() => setOpenModal(!openModal)}
                />
              </div>
            )}
          </div>

          {houseLoading ? (
            <div className="mt-3">
              <TableLoader />
            </div>
          ) : houseCollection?.data?.length ? (
            <InfoTable
              columns={houseColumns((rowId, action) =>
                handleAction(rowId, action)
              )}
              dataCollection={houseCollection?.data}
              rightTextAsButton={true}
              pointerOnHover={true}
              highlightOnHover={true}
              loadingTable={loadingHouseTable}
              paginationTotalRows={houseCollection?.totalRecords}
              onChangePage={(page) => setHousePagination(page)}
              onRowClicked={(row, event) =>
                navigate(`/properties/house/${row.id}`)
              }
            />
          ) : (
            <div className="my-5">
              <CenteredText title="No Houses Added Yet">
                If a house exists in an estate, create an estate and add the
                house under that estate. Houses added under estates will also
                populate here. You can also add an individual house (a house not
                in an estate or a standalone house) by clicking the “add house”
                button above.
              </CenteredText>
            </div>
          )}
        </>
      ) : (
        <div className="my-5">
          <CenteredText title={"Unauthorized Access"}>
            You don't have permission to view houses. Please contact the
            organization administrator for assistance.
          </CenteredText>
        </div>
      )}

      <Modal
        show={openModal}
        closeModal={() => navigate("/properties/house/create-house")}
        title={"Does this House belong in an Estate?"}
        subTitle={"Is this house located in an estate?"}
        confirmModal={() => {
          setOpenModal(false);
          setOpenEstateModal(true);
        }}
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
      />

      <Modal
        show={openEstateModal}
        title={"Select The Estate This House Should Be Added To."}
        width={"100%"}
        height={"100%"}
        closeModalIcon
        titleFontSize={"30px"}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Proceed"}
        confirmModal={() =>
          navigate(`/properties/house/create-house/${selectedEstateId}`)
        }
        closeModal={() => {
          setSelectedEstateId(null);
          setOpenEstateModal(false);
        }}
        disabled={!selectedEstateId}
      >
        <div className="px-3">
          <p className="m-0">
            Can't find the estate?{" "}
            <span
              className="anchor"
              onClick={() => navigate("/properties/estate/create-estate")}
            >
              Create the Estate
            </span>
          </p>
          <div className="row">
            {estates?.length > 0 ? (
              estates?.map((estate) => (
                <div key={estate.id} className="col-3">
                  <EstateCard
                    estate={estate}
                    selected={estate.id === selectedEstateId}
                    onSelect={handleSelectEstate}
                  />
                </div>
              ))
            ) : (
              <div className="my-5">
                <CenteredText title={"No Estates Found"}>
                  An estate is a collection of houses in the same location.
                  Close and then click the “add estate” button to create an
                  estate and then add the types of houses that are available in
                  the estate.
                </CenteredText>
              </div>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        show={showModal}
        title={"You are about to delete a house"}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        isBtnLoading={deleteHouse.isLoading}
        closeModal={() => setShowModal(false)}
        confirmModal={() =>
          deleteHouse.mutate(null, {
            onSuccess: () => {
              setShowModal(false);
              refetchHouseCollection();
            },
          })
        }
      >
        <div className="text-center">
          Are you sure you want to delete {houseToDelete?.name ?? ""},{" "}
          {convertToTitleCase(houseToDelete?.type)} - {houseToDelete?.address}?{" "}
          <strong className="text-danger">This action can't be undone</strong>
        </div>
      </Modal>

      {/* SELECT PLAN TYPE  */}
      <Modal
        show={showPlanTypeModal}
        closeModal={() => {
          setPlanType("");
          setInterestType("");
          setShowPlanTypeModal(false);
        }}
        title={
          showSelectInterestOrCompoundType
            ? "Select Interest Type"
            : "Select Plan Type"
        }
        subTitle={
          showSelectInterestOrCompoundType
            ? "What type of interest rate does this mortgage plan use?"
            : "What kind of plan do you want to create? Select One"
        }
        confirmModal={() => {
          if (
            removeUnderscore(planType) === "MORTGAGEPLAN" &&
            !showSelectInterestOrCompoundType
          ) {
            return setShowSelectInterestOrCompoundType(true);
          }
          navigate(`/properties/house/plans/create/${houseId}`);
          setShowPlanTypeModal(false);
        }}
        closeModalIcon={true}
        backBtn={showSelectInterestOrCompoundType}
        onBtnClick={() => {
          setPlanType(null);
          handleSelectInterestType(null);
          setShowSelectInterestOrCompoundType(false);
        }}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Continue"}
        width={"100%"}
        height={"100%"}
        crossLine={false}
        titleColor={colors.darkGreen}
        titleFont={"Millik"}
        titleFontSize={"30px"}
      >
        <>
          {showSelectInterestOrCompoundType ? (
            <div className="select_interest_type d-md-flex mt-5">
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Simple Interest or Markup Rate"}
                  iconName={"simpleInterest"}
                  description={
                    "This is a simple markup on the property price. For example, if a house is ₦50 Million with a 10% simple interest or markup rate, they’ll repay ₦50 Million + ₦5 Million (the 10% extra markup rate)."
                  }
                  onChange={() => handleSelectInterestType("SIMPLE_INTEREST")}
                  onClick={() => handleSelectInterestType("SIMPLE_INTEREST")}
                  selected={interestType === "SIMPLE_INTEREST"}
                />
              </div>

              <p className="text-center">OR</p>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Compound Interest"}
                  iconName={"compoundInterest"}
                  description={
                    "This is a compounding interest rate which is used in most traditional mortgages. Compounding rates accrue interest on a monthly basis. For example, if a house is ₦50 Million with a 10% compund interest, it means the 10% interest rate will compound monthly. We calculate the compund rate automatically and attach it to the customer’s repayment schedule."
                  }
                  onChange={() => handleSelectInterestType("COMPOUND_INTEREST")}
                  onClick={() => handleSelectInterestType("COMPOUND_INTEREST")}
                  selected={interestType === "COMPOUND_INTEREST"}
                />
              </div>
            </div>
          ) : (
            <div className="row m-0 mt-4">
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Outright Plan"}
                  iconName={"outrightPlan"}
                  description={
                    "With outright plans, customers pay 100% of the purchase price of the property in one bulk payment. There is no repayment necessary."
                  }
                  onChange={() => handleSelectPlanType("OUTRIGHT_PLAN")}
                  onClick={() => handleSelectPlanType("OUTRIGHT_PLAN")}
                  selected={planType === "OUTRIGHT_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Payment Plan"}
                  iconName={"paymentPlan"}
                  description={
                    " Payment plans allow customers pay a downpayment then spread the  remaining payment over a fixed time period."
                  }
                  onChange={() => handleSelectPlanType("PAYMENT_PLAN")}
                  onClick={() => handleSelectPlanType("PAYMENT_PLAN")}
                  selected={planType === "PAYMENT_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={" FMBN Rent To Own"}
                  iconName={"FMBN"}
                  description={
                    " This is a plan offered by the Federal Mortgage Bank of Nigeria.vIf you have partnered with FMBN to sell properties through their Rent To Own Plan, you may use this plan to boost your sales and collect applications and manage sales and documentation. However, you will be responsible for processing the customer’s application to FMBN."
                  }
                  onChange={() => handleSelectPlanType("RENT_TO_OWN")}
                  onClick={() => handleSelectPlanType("RENT_TO_OWN")}
                  selected={planType === "RENT_TO_OWN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={" Mortgage Plan"}
                  iconName={"mortgagePlan"}
                  description={
                    " This is a plan that attracts interest payments from customers and is paid over several years. Interest on mortgages can be simple or compond interest. We accomodate for both interest types."
                  }
                  cursor={"pointer"}
                  onChange={() => handleSelectPlanType("MORTGAGE_PLAN")}
                  onClick={() => handleSelectPlanType("MORTGAGE_PLAN")}
                  selected={planType === "MORTGAGE_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"  National Housing Fund"}
                  iconName={"nationalHousingFund"}
                  description={
                    "This is a plan from the Federal Mortgage Bank of Nigeria. If you are authorized by FMBN to sell NHF mortgages, Rent To Own you can do so through here. However, you will be responsible for processing the customer’s application to FMBN."
                  }
                  onChange={() => handleSelectPlanType("NHF_PLAN")}
                  onClick={() => handleSelectPlanType("NHF_PLAN")}
                  selected={planType === "NHF_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Family Homes Fund (FMF) Help To Own."}
                  iconName={"FMBN"}
                  description={
                    "This is a mortgage plan that offers loans of up to ₦35 Million Naira for customers to buy a house at 14% interest for up to 20 years."
                  }
                  onChange={() => handleSelectPlanType("HELP_TO_OWN")}
                  onClick={() => handleSelectPlanType("HELP_TO_OWN")}
                  selected={planType === "HELP_TO_OWN"}
                />
              </div>
            </div>
          )}
        </>
      </Modal>
    </div>
  );
};

export default Properties;
